// import router from '@/router';
import { ref } from 'vue'
import { defineStore } from 'pinia'
import {router} from '@/plugins/1.router'
import axiosAuth from '@/utils/AxiosAuth'
import { useRuntimeStore } from '@/stores/runtimeStore'
import { useQuickbooks } from '@/stores/quickbooksStore'
import { storeToRefs } from 'pinia'
import { notify } from 'notiwind'
import { cloneDeep } from 'lodash'
import { lookupCustomer, createCustomer } from '@/services/quickbooksServices'
import { useAttach } from '@/stores/attachmentStore'

import { useAdmin } from '@/stores/adminStore'
import {v4 as uuidv4} from "uuid";

export const useClientStore = defineStore('clientStore', () => {
  const isNew = ref(false)
  const selectedClient = ref({})
  const editedClient = ref({})
  const isLoading = ref(false)
  const isEditing = ref(false)
  const clientMessages = ref([])
  const clientList = ref([])
  const activityList = ref([])
  const refferedBy = ref([])
  const clientServiceAddresses = ref([])
  const clientImportErrors = ref([])
  const importIsPending = ref(false)
  const isAddServiceAddressVisible = ref(false)
  const selectedTab = ref('profile')
  const importModal = ref(false)

  const importStatus = ref({
    file_name: null,
    rows: 1,
    rows_processed: 0,
    clients_to_import: 1,
    clients_imported: 0,
    errors: [],
    finished: false
  })

  // const importStatus = ref(null)

  const root = useRuntimeStore().$state.apiRoot
  // const { createQBCustomer } = useQuickbooks()

  const toggleEditing = () => {
    isEditing.value = !isEditing.value
  }

  const setNewClient = () => {
    isNew.value = true
    isEditing.value = true
    editedClient.value = {
      name: '',
      type: '',
      arborist: null,
      primary_contact: {
        name: {
          first: '',
          last: ''
        },
        email: '',
        phone: ''
      },
      lead_source: '',
      referred_by_id: ''
    }
  }

  const editClient = () => {
    isNew.value = false
    isEditing.value = true
    editedClient.value = cloneDeep(selectedClient.value)
  }

  const getClientListSimple = async (params) => {
    await axiosAuth
      .get(`${root}/clients/`, params)
      .then((res) => {
        clientList.value = res.data
      })
      .catch((err) => {
        console.log(err)
        notify(
          {
            group: 'error',
            title: 'Error Retrieving Client List',
            text: 'We a problem retrieving the client list.'
          },
          2000
        )
      })
  }

  const getReferredData = async (params) => {
    let data 
    await axiosAuth
      .get(`${root}/clients/referred_by/${params}`)
      .then((res) => {
        refferedBy.value = res.data
        data = res.data
      })
      .catch((err) => {
        console.log(err)
        notify(
          {
            group: 'error',
            title: 'Error Retrieving Client List',
            text: 'We a problem retrieving the client list.'
          },
          2000
        )
      })

      return data
  }


  const getClientById = async (id) => {
    const { resetImageUrl } = useAttach()
    await axiosAuth
      .get(`${root}/clients/${id}`)
      .then((res) => {
          resetImageUrl()
          selectedClient.value = res.data
      })
      .catch((err) => {
        if (err.response && err.response.status == 500) {
          console.log(err.response)
          notify(
            {
              group: 'error',
              title: 'Error Retrieving Client',
              text: 'We a problem retrieving this client info.'
            },
            2000
          )
        }
      })
  }

  const createReference = async (params) => {
    let data 
    await axiosAuth
      .post(`${root}/clients/create_reference`, params)
      .then((res) => {
        refferedBy.value = res.data
      })
      .catch((err) => {
        console.log(err)
        notify(
          {
            group: 'error',
            title: 'Error Retrieving Client List',
            text: 'We a problem retrieving the client list.'
          },
          2000
        )
      })

      return data
  }


  const editReferredData = async (params) => {
    let data 
    await axiosAuth
      .put(`${root}/clients/update_reference`, params)
      .then((res) => {
        refferedBy.value = res.data
      })
      .catch((err) => {
        console.log(err)
        notify(
          {
            group: 'error',
            title: 'Error Retrieving Client List',
            text: 'We a problem retrieving the client list.'
          },
          2000
        )
      })

      return data
  }


    const deleteReferredData = async (id) => {
      await axiosAuth
      .get(`${root}/clients/delete_reference/${id}`)
      .then((res) => {
      refferedBy.value = res.data
      })
      .catch((err) => {
        console.log(err)
       })
    }

  const getServiceAddressesByClient = async (id) => {
    await axiosAuth
      .get(`${root}/clients/${id}`)
      .then((res) => {
        clientServiceAddresses.value = res.data.service_addresses
      })
      .catch((err) => {
        if (err.response && err.response.status == 500) {
          console.log(err.response)
          notify(
            {
              group: 'error',
              title: 'Error Retrieving Client',
              text: 'We a problem retrieving this client info.'
            },
            2000
          )
        }
      })
  }
    const setSelectedClient = (item) => {
      isNew.value = false
      selectedClient.value = item
      let referred_by_id = selectedClient.value.referred_by_id
      let refferedByData = refferedBy.value.referred_by
      let isPresent = refferedByData.some(item => item.id === referred_by_id);
      if( !isPresent ){
          selectedClient.value.referred_by_id = null
      }
  }
  const createClient = async (newClient) => {
    let result
    let preparedClient = {
      ...newClient,
      arborist: { id: newClient.arborist }
    }

    await axiosAuth
      .post(`${root}/clients/`, preparedClient)
      .then(async (res) => {
        result = res.data
        console.log(res.data)
        notify(
          {
            group: 'success',
            title: 'New Client Created',
            text: 'Client created successfully.'
          },
          2000
        )
        // let found = await lookupCustomer(editedClient.value.id)
        // console.log(found)
        selectedClient.value = result
        const arboristID = selectedClient.value.arborist.id
        selectedClient.value.arborist = {
          id: arboristID
        }
        editedClient.value.arborist = {
          id: arboristID
        }

        // create the initial primary contact if the client is created
        if (
          res.status === 201 &&
          selectedClient.value.id &&
          preparedClient.primary_contact
        ) {
          for (const contact of preparedClient.primary_contact) {
            const response = await axiosAuth.post(`${root}/clients/contacts`, {
              client: { id: selectedClient.value.id },
              ...contact,
              active: true,
              types: ['primary']
            });
          }
    
        }

        // commented out QB lookup and create because the API handles this now
        // let found = await lookupCustomer(selectedClient.value.name)
        // if (found?.QueryResponse?.Customer) {
        //   console.log('Customer is found in Quickbooks')
        // } else {
        //   console.log('Customer is not found, create one.')
        //   await createCustomer(selectedClient.value.id)
        // }
        // router.replace(`/apps/clients/client_view/${result.id}`)
      })
      .catch((err) => {
        console.log(err)
        notify(
          {
            group: 'error',
            title: 'Error Creating Client',
            text: 'We had a problem creating this Client.'
          },
          2000
        )
      })
  }

  const updateClientPaymentMethods = async (client) => {
    await axiosAuth
      .put(`${root}/clients/${client.id}`, client)
      .then((res) => {
        console.log(res.data)
        notify(
          {
            group: 'success',
            title: 'Client Saved',
            text: 'Client saved successfully.'
          },
          2000
        )
      })
      .catch((err) => {
        console.log(err)
        notify(
          {
            group: 'error',
            title: 'Error Saving Client',
            text: 'We had a problem saving this Client.'
          },
          2000
        )
      })
  }

    const getActivityList = async () => {
      await axiosAuth
      .get(`${root}/activities/list/${selectedClient.value.id}`)
      .then((res) => {
          activityList.value = res.data
      })
      .catch((err) => {
        console.log(err)

      })
  }

  const saveClient = async () => {
    // const { quickbooksStatus } = storeToRefs(useQuickbooks())
      const { adminSettings} = storeToRefs(useAdmin())
    let result
    //Line 161-162 This is done because if the value of editedClient.value.arborist is an object with values other than id (this occurs upon first edit on a new client), the API will throw an error. You have to reassign the value as an object with only the id property.
    if (editedClient.value.arborist.name) {
      const arboristID = editedClient.value.arborist.id
      editedClient.value.arborist = arboristID
    }

    let preparedClient = {
      ...editedClient.value,
      arborist: {
        id: editedClient.value.arborist
      }
    }
    if (adminSettings.value.quickbooks_token_active) {
      let found = await lookupCustomer(editedClient.value.name)
      if (found.QueryResponse.Customer) {
        console.log('Customer is found in Quickbooks')
      } else {
        let qbId = await createCustomer(editedClient.value.id)
        console.log('qbId', qbId)
        if (qbId) {
          preparedClient.quickbooks_id = qbId?.Customer?.Id
        }
      }
    }
    delete preparedClient.service_addresses
    delete preparedClient.contacts
    delete preparedClient.jobs
    delete preparedClient.primary_contact
      console.log(preparedClient,"<<<<,,save client >>>>>>>>")
    await axiosAuth
      .put(`${root}/clients/${editedClient.value.id}`, preparedClient)
      .then((res) => {
        result = res.data

        notify(
          {
            group: 'success',
            title: 'Client Saved',
            text: 'Client updated successfully.'
          },
          2000
        )

        selectedClient.value = result
      })
      .catch((err) => {
        console.log(err)
        notify(
          {
            group: 'error',
            title: 'Error Saving Client',
            text: 'We had a problem saving this Client.'
          },
          2000
        )
      })
  }

  const getMessagesByClient = async (clientId, serviceAddressId) => {
    const serviceAddressParam = serviceAddressId
      ? `?service_address_id=${serviceAddressId}`
      : ''
    let apiRoute = `/clients/${clientId}/message/${
      serviceAddressParam ? serviceAddressParam : null
    }`
    await axiosAuth
      .get(`${root}${apiRoute}`)
      .then((res) => {
        console.log(res.data)
        clientMessages.value = res.data
      })
      .catch((err) => {
        notify(
          {
            group: 'error',
            title: 'Error Retrieving Messages',
            text: 'We a problem retrieving messages.'
          },
          2000
        )
        console.log(err)
      })
  }

  const downloadClientTemplate = async () => {
    await axiosAuth
      .get(`${root}/clients/import`, {
        responseType: 'blob'
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'client_template.csv')
        document.body.appendChild(link)
        link.click()
      })
      .catch((err) => {
        console.log(err)
        notify(
          {
            group: 'error',
            title: 'Error Downloading Template',
            text: 'We a problem downloading the template.'
          },
          2000
        )
      })
  }

    const uploadClientTemplate = async (file) => {
        importIsPending.value = true
        let formData = new FormData()
        formData.append('file_obj', file)

        try {
            const response = await axiosAuth.post(`${root}/clients/import`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) =>{
             clientImportErrors.value = res.data
                notify(
                {
                    group: 'success',
                    title: 'Template Uploaded',
                    text: 'Template uploaded successfully.'
                },
            );
            })
            // Update import status
            importStatus.value.rows = 1;
            importStatus.value.rows_processed = 0;

        } catch (err) {
            console.log(err)
            notify(
                {
                    group: 'error',
                    title: 'Upload Failed',
                    text: 'There was an error uploading the template.'
                },
                2000
            );

            // Handle errors
            clientImportErrors.value = err.response || 'An unknown error occurred.';

            // Display warning banner with errors if available
            if (clientImportErrors.value) {
                notify(
                    {
                        group: 'warning',
                        title: 'Import Errors',
                        text: clientImportErrors.value
                    },
                );
            }

        } finally {
            importIsPending.value = false;
        }
    };


  const getImportLogs = async () => {
    if (!importIsPending.value) return
    const root = useRuntimeStore().$state.apiRoot
    await axiosAuth
      .get(`${root}/clients/import/logs`)
      .then((res) => {
        importStatus.value = res.data[0]
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const values = {
    isNew,
    selectedClient,
    editedClient,
    isLoading,
    isEditing,
    refferedBy,
    clientMessages,
    clientServiceAddresses,
    clientList,
    clientImportErrors,
    importIsPending,
    importStatus,
    activityList,
    selectedTab,
    isAddServiceAddressVisible,
    importModal
  }

  const actions = {
    toggleEditing,
    setNewClient,
    createClient,
    editClient,
    getClientListSimple,
    getReferredData,
    editReferredData,
    createReference,
    getClientById,
    saveClient,
    setSelectedClient,
    getMessagesByClient,
    getServiceAddressesByClient,
    downloadClientTemplate,
    uploadClientTemplate,
    updateClientPaymentMethods,
    getImportLogs,
    deleteReferredData,
      getActivityList

  }

  return {
    ...values,
    ...actions
  }
})
