import axios from 'axios'
import { storeToRefs } from 'pinia'
import { useAuth } from '@/stores/authStore'
import { router } from '@/plugins/1.router'

const axiosAuth = axios.create({})
axiosAuth.interceptors.request.use(async (config) => {
  const { accessToken } = storeToRefs(useAuth())

  config.timeout = 800000
  if (accessToken.value && localStorage.getItem('accessToken') != null ) {
    config.headers['Authorization'] = `Bearer ${accessToken.value}`
  }
  else{
    if(!(router.currentRoute.value.meta.unauthenticatedOnly || router.currentRoute.value.meta.public)){
    await router.push('/login')
      }
  }
  return config; // return the config object
})
axiosAuth.interceptors.response.use(null, async (err) => {
  if (err.response && err.response.status === 401) {
    const { refreshAccessToken } = useAuth()
    await refreshAccessToken()
    if (localStorage.getItem('accessToken') != null ){
    return axiosAuth.request(err.config)
    }
  }
  throw err
})

export default axiosAuth
