import {storeToRefs} from "pinia";
import {useRuntimeStore} from "@/stores/runtimeStore.js";
import {useCookie} from "@core/composable/useCookie.js";
import Intercom from "@intercom/messenger-js-sdk";

export const initIntercom = () =>{
    const { intercom_app_id } = storeToRefs(useRuntimeStore())
    const intercomUserValue = useCookie('intercomUser').value;
    if (intercomUserValue) {
        try {
            Intercom({
                app_id: intercom_app_id.value,
                user_id: intercomUserValue,
                user_hash: useCookie('intercomHash').value,
                alignment: 'right',
                horizontal_padding: 70,
                vertical_padding: 40
            });
        } catch (error) {
            console.error('Error initializing Intercom:', error);
        }
    }
}
