<script setup>
import { useTheme } from 'vuetify'
import ScrollToTop from '@core/components/ScrollToTop.vue'
import initCore from '@core/initCore'
import { useJobStore } from '@/stores/jobStore'
const { selectedJob } = storeToRefs(useJobStore())
import { useGeoJson } from '@/stores/geoJsonStore'
import {
  initConfigStore,
  useConfigStore,
} from '@core/stores/config'
import { hexToRgb } from '@layouts/utils'
const loadingMap = ref(false)
const {
  mapModal,
  jobFeature,
  locationEnabled,
  zoomLevel,
  mapCenter,
  userPosition
} = storeToRefs(useGeoJson())
const { global } = useTheme()
 mapCenter.value = [-98.5795, 39.8283]
userPosition.value = [-98.5795, 39.8283]
zoomLevel.value = 3
const getUserLocation = async () => {
  if ('geolocation' in navigator) {
    try {
      navigator.geolocation.getCurrentPosition(
          function (position) {
            locationEnabled.value = true
            if (!selectedJob.value) {
              let result = [position.coords.longitude, position.coords.latitude]
              mapCenter.value = result
              userPosition.value = result
              zoomLevel.value = 13
            }
            return
          },
          function () {
            console.log('User denied location access')
            if (!selectedJob.value) {
              mapCenter.value = [-98.5795, 39.8283]
              userPosition.value = [-98.5795, 39.8283]
              zoomLevel.value = 3
            }
          }
      )
    }catch (e){
      console.log("Error While accessing getCurrentPosition", e);
      if (!selectedJob.value) {
        mapCenter.value = [-98.5795, 39.8283]
        userPosition.value = [-98.5795, 39.8283]
        zoomLevel.value = 3
      }
    }
  }
}
watchEffect(async () => {
  if (!userPosition.value) {
    await getUserLocation()
  }
})
onBeforeMount(() => {
  getUserLocation()
})

// ℹ️ Sync current theme with initial loader theme
initCore()
initConfigStore()

const configStore = useConfigStore()
</script>

<template>
  <VLocaleProvider :rtl="configStore.isAppRTL">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
      <RouterView />
      <ScrollToTop />
    </VApp>
  </VLocaleProvider>
</template>
