import { format } from 'date-fns';

export const formatUTCDate = (utcDate) => {
  let result = new Date(utcDate)
  let options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  }

  const dateFormat = new Intl.DateTimeFormat('en-US', options)

  result = `${dateFormat.format(result)}`
  return result
}

export const formatDate = (utcDate) => {
  // Extract the date part from the string (first 10 characters: YYYY-MM-DD)
  const datePart = utcDate.substring(0, 10);

  // Split the date part into components
  // Format the date as MM/DD/YYYY
  const date = new Date(utcDate)
  const month = date.getMonth()
  const day = date.getDay()
  const year = date.getFullYear()


  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
}

export const formatDateString = (utcDate) => {
  // Extract the date part from the string (first 10 characters: YYYY-MM-DD)
  // utcDate = new Date(utcDate);
  if (!utcDate) return
  let lastChar = utcDate[utcDate.length - 1]
  if (lastChar !== 'Z') {
    utcDate = utcDate + 'Z'
  }
  // Split the date part into components
  // Format the date as MM/DD/YYYY
  const date = new Date(utcDate)
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear()


  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
}

export const getTodayWithStartTime = () => {
  const now = new Date();

  // Create a new Date object for today with the time set to 00:00:00
  const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate()).toISOString();
  return todayStart;
}
export const getTodayEndTime = () => {
 const now = new Date();

now.setHours(23, 59, 59, 999);

const todayEndUTC = now.toISOString();
  return todayEndUTC;
}

export const formatISTDate = (utcDate) => {
  let result = new Date(utcDate);

  // Adjust for IST (UTC +5:30)
  result.setUTCHours(result.getUTCHours() + 5);
  result.setUTCMinutes(result.getUTCMinutes() + 30);

  // Define options for date formatting
  let options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  };

  // Format the date
  const dateFormat = new Intl.DateTimeFormat('en-US', options);
  result = dateFormat.format(result);

  return result;
}

export const formatISTTime = (utcDate) => {
  let result = new Date(utcDate);

  // Adjust for IST (UTC +5:30)
  result.setUTCHours(result.getUTCHours() + 5);
  result.setUTCMinutes(result.getUTCMinutes() + 30);

  // Define options for Time formatting
  let options = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false // 24-hour format
  };

  // Format the Time
    const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
  result = dateTimeFormat.format(result);

  return result;
}
export const formatUTCDateWithTime = (utcDate) => {
  let result = new Date(utcDate)
  let options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  }
  const timeOptions = {
    timeStyle: 'short'
  }
  const dateFormat = new Intl.DateTimeFormat('en-US', options)
  const timeFormat = new Intl.DateTimeFormat('en-US', timeOptions)
  result = `${dateFormat.format(result)} ${timeFormat.format(result)}`
  return result
}



export const formatToStandardDate = (utcDate, mmddyy) => {
  // returns YYYY-MM-DD
  const date = new Date(utcDate)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  if (mmddyy) return `${month}/${day}/${year}`
  else return `${year}-${month}-${day}`
}
export const formatToStandardISTDate = (utcDate, mmddyy) => {
  const date = new Date(utcDate);

  date.setUTCHours(date.getUTCHours() + 5);
  date.setUTCMinutes(date.getUTCMinutes() + 30);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  if (mmddyy) {
    return `${month}/${day}/${year}`;
  } else {
    return `${year}-${month}-${day}`;
  }
}
export const formatRecurringDate = (utcDate) => {
  const date = new Date(utcDate)
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${month}/${day}`
}

export const getUTCTime = (utcDateString) => {
  if (!utcDateString) return
  let lastChar = utcDateString[utcDateString.length - 1]
  if (lastChar !== 'Z') {
    utcDateString = utcDateString + 'Z'
  }
  const date = new Date(utcDateString)
  const hours = date.getHours() % 12 || 12
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const amOrPm = date.getHours() >= 12 ? 'PM' : 'AM'
  return `${hours}:${minutes} ${amOrPm}`
}

export const getTimeFromISODate = (isoDateStr) => {
  // Create a new Date object from the ISO 8601 date string
  const date = new Date(isoDateStr);

  // Extract the hours, minutes, and seconds
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  // Format the time as HH:MM:SS
  const time = `${hours}:${minutes}:${seconds}`;

  return time;
}

export const formatTime = (dateString, withText) => {
  // if (!dateString) return null
  // let lastChar = dateString[dateString.length - 1]
  // if (lastChar !== 'Z') {
  //   dateString = dateString + 'Z'
  // }
  let result = new Date(dateString)
  const timeOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23'
  }
  const timeOptionsText = {
    hour: '2-digit',
    minute: '2-digit'
  }
  let options = withText ? timeOptionsText : timeOptions
  const timeFormat = new Intl.DateTimeFormat('en-US', options)

  result = `${timeFormat.format(result)}`
  return result
}

export const extractDayInfo = (dateString) => {
  const date = new Date(dateString)
  const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' })
  const dayOfMonth = date.getDate()

  return { day: dayOfWeek, dayOfMonth: dayOfMonth }
}

export const toUTCDateString = (dateString) => {
  const date = new Date(dateString)
  return date.toISOString()
}

export const daysUntilDate = (endDate) => {
  const today = new Date();
  const end = new Date(endDate);
  // Calculate the difference in milliseconds
  const diff = end - today;

  // Convert the difference to days and round down to the nearest whole number
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  return days;
}

export const roundToNearest15Minutes = (timeString) => {
  const [hours, minutes] = timeString.split(':').map(Number);
  const totalMinutes = hours * 60 + minutes;
  const roundedMinutes = Math.ceil(totalMinutes / 15) * 15;
  const roundedHours = Math.floor(roundedMinutes / 60);
  const remainingMinutes = roundedMinutes % 60;
  const roundedTimeString = `${roundedHours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;
  return roundedTimeString;
};