<script setup>
import { VForm } from 'vuetify/components/VForm'
import AuthProvider from '@/views/pages/authentication/AuthProvider.vue'
import { themeConfig } from '@themeConfig'
import tree1 from '@images/misc/tree1.png'
import authV2LoginIllustrationBorderedDark from '@images/pages/auth-v2-login-illustration-bordered-dark.png'
import authV2LoginIllustrationBorderedLight from '@images/pages/auth-v2-login-illustration-bordered-light.png'
import authV2LoginIllustrationDark from '@images/pages/auth-v2-login-illustration-dark.png'
import authV2LoginIllustrationLight from '@images/pages/auth-v2-login-illustration-light.png'
import authV2MaskDark from '@images/pages/mask-v2-dark.png'
import authV2MaskLight from '@images/pages/mask-v2-light.png'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import {useAuth} from '@/stores/authStore'
import bgLogin from '@/assets/images/login_background.jpg'
import tree3 from '@images/misc/tree3.png'
import { disabled } from '@/views/demos/components/pagination/demoCodePagination'
import {onBeforeMount} from "vue";
import {useProfileStore} from "@/stores/profileStore.js";
const profileStore = useProfileStore()

const authThemeImg = useGenerateImageVariant(authV2LoginIllustrationLight, authV2LoginIllustrationDark, authV2LoginIllustrationBorderedLight, authV2LoginIllustrationBorderedDark, true)
const authThemeMask = useGenerateImageVariant(authV2MaskLight, authV2MaskDark)

definePage({
  meta: {
    layout: 'blank',
    unauthenticatedOnly: true,
  },
})
const { forgetPassword ,getUsers} = useAuth()
const isPasswordVisible = ref(false)
const route = useRoute()
const router = useRouter()
const ability = useAbility()

const errors = ref({
  email: undefined,
  password: undefined,
})

const refVForm = ref()

const credentials = ref({
  email: '',
  password: '',
})

const requestInitiated = ref(false)
const requestSubmitted = ref(false)
const isSnackbarFadeVisible = ref(false)
const isSnackbarErrorFadeVisible = ref(false)
const isExists = ref(false)
const requestResetPassword = async () => {
  requestInitiated.value = true
  try {
    await forgetPassword({
      username: credentials.value.email,
    });
    requestSubmitted.value = true;
    isSnackbarFadeVisible.value = true;
  } catch (error) {
    requestInitiated.value = false
    isSnackbarErrorFadeVisible.value = true;
    console.error('Error requesting password reset:', error);
  }
}

const userValidate = async (value) => {
  const users = await getUsers(value)
  if (users[0][1] !== null) {
    isExists.value = true
    return true;
  } else {
    isExists.value = false
    return 'Username does not exist';
  }
}
const validateUsername = async () => {
    await userValidate(credentials.value.email).then(result => {
      if (typeof result === 'string') {
        errors.value.email = result;
      } else {
        errors.value.email = undefined;
      }
    });

}

const onSubmit =async () => {
   await validateUsername()
  if(isExists.value){
     refVForm.value?.validate().then(({ valid: isValid }) => {
    if (isValid)
      requestResetPassword()
  })
  }

}
</script>

<template>

  <section
      class="relative flex items-center justify-center min-h-screen bg-cover"
      :style="{ backgroundImage: `url(${bgLogin})`, backgroundSize: `cover` }"
  >
    <div class="auth-wrapper d-flex align-center justify-center pa-4">
      <VCard
          v-if="!requestSubmitted"
          class="auth-card pa-sm-4 pa-md-7 pa-0"
          max-width="448"
      >
        <VCardText>
          <div class="d-flex align-center gap-x-3 justify-center mb-6">
            <VNodeRenderer :nodes="themeConfig.app.logo" />

            <h1 class="auth-title">
              {{ themeConfig.app.title }}
            </h1>
          </div>
          <h4 class="text-h4 mb-1">
            Forget Password
          </h4>
          <p class="mb-0">
            Enter your username and we'll send you instructions to reset your password
          </p>
        </VCardText>

        <VCardText>
          <VForm
              ref="refVForm"
              @submit.prevent="onSubmit"
          >
            <VRow>
              <!-- email -->
              <VCol cols="12">
                <VTextField
                    v-model="credentials.email"
                    label="Username"
                    placeholder=""
                    autofocus
                    :rules="[requiredValidator,usernameValidator]"
                    :error-messages="errors.email"
                    @blur="validateUsername"
                />
              </VCol>

              <!-- password -->
              <VCol cols="12">
                <VBtn
                    block
                    type="submit"
                    :disabled="requestInitiated"
                >
                  Request Reset
                </VBtn>
              </VCol>
              <VCol cols="12">
                <RouterLink
                    class="d-flex align-center justify-center"
                    :to="{ name: 'login' }"
                >
                  <VIcon
                      icon="ri-arrow-left-s-line"
                      class="flip-in-rtl"
                  />
                  <span>Back to login</span>
                </RouterLink>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>      
      </VCard>
      <VCard
          v-else
          class="auth-card pa-sm-4 pa-md-7 pa-0"
          max-width="448"
      >
      <VCardText>
          <div class="d-flex align-center gap-x-3 justify-center mb-6">
            <VNodeRenderer :nodes="themeConfig.app.logo" />

            <h1 class="auth-title">
              {{ themeConfig.app.title }}
            </h1>
          </div>
          <h4 class="text-h4 mb-1">
            We’ve received your request
          </h4>
          <p class="mb-0">
            If a profile matching the provided email or username is found you will receive an email to
        reset your password.
          </p>
        </VCardText>
       
        <VCardText>
            <VRow>
              <VCol cols="12">
                <RouterLink
                    class="d-flex align-center justify-center"
                    :to="{ name: 'login' }"
                >
                  <span>< Back to login</span>
                </RouterLink>
              </VCol>
            </VRow>
        </VCardText>
  </VCard>
    </div>
  </section>
  <VSnackbar
      class="custom-snackbar"
      v-model="isSnackbarFadeVisible"
      transition="fade-transition"
      location="top end"
      height="75px"
      color="success"
      origin="overlap"
      timeout="2000"
    >
    <div>
        <div style="display: flex; align-items:center;">
          <VIcon icon="ri-error-warning-line" style="background-color: #0a7827;" size="24" />&nbsp; &nbsp;&nbsp;
          <div>
            <div class=" text-h6 font-weight-bold " style="color: #0a7827; font-weight: 500;">Email Sent</div>
            <div style="color: forestgreen;">Forget Password email has been sent</div>
          </div>
        </div>
      </div>
    </VSnackbar>

    <VSnackbar
      class="custom-snackbar"
      v-model="isSnackbarErrorFadeVisible"
      transition="fade-transition"
      location="top end"
      height="75px"
      color="error"
      timeout="2000"
    >
    <div>
        <div style="display: flex; align-items:center;">
          <VIcon icon="ri-error-warning-line" size="24" /> &nbsp;&nbsp;
          <div>
            <div class=" text-h6 font-weight-bold " style="color: aliceblue; font-weight: 500;">Email Not Sent</div>
            <div>An error occurred while sending email</div>
          </div>
        </div>
      </div>
    </VSnackbar>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";
</style>
