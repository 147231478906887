import { defineStore, storeToRefs } from 'pinia';
import axiosAuth from '@/utils/AxiosAuth'; 
import { ref } from 'vue';
import { useRuntimeStore } from './runtimeStore';
import { useUserStore } from './userStore';
import { getUserAbilities } from '@/utils/userAbilityRules';
import jwt_decode from 'jwt-decode'
import { useAuth } from './authStore';
import jwtDecode from 'jwt-decode';
import { useCookie } from '@/@core/composable/useCookie';
import { notify } from 'notiwind';
import {useAdmin} from "@/stores/adminStore.js";
import axios from 'axios'
import { useNotification } from './notificationStore';

export const useSuperAdmin = defineStore('superAdminStore', () => {
  const root = useRuntimeStore().$state.apiRoot
  const superAdminsList = ref([]); 
  const isLoading = ref(true)
  const jobsStatsData = ref()
  const statsAndLeaders = ref()
  const graphStats = ref()
  const lestYearGraphStats = ref()
  const newSuperAdminData = ref({
    name: {first: '', last: ''},
    email: '',
    phone: ''
  })
  const total_count = ref('')
  const topReferralSource = ref()
  const clientAddedToday = ref()

  const getSuperAdminList = async (data) => {
    try {
      const res = await axiosAuth
      .get(`${root}/super-admin/`,data)
      .then((res) => {
      isLoading.value=false 
      superAdminsList.value = res.data.super_admins;
      total_count.value = res.data.count
    })}
     catch (error) {
      isLoading.value=false 
      console.error(error);
       notify(
           {
             group: "error",
             title: 'Error',
             text: error?.response?.data?.message,
           },
           2000
       );
    }
  };

  const createSuperAdmin = async (data) => {
    try {
      const res = await axiosAuth.post(`${root}/super-admin/`,data).then((res) =>{
        superAdminsList.value.push(res.data)
        newSuperAdminData.value.name['first'] = ''
        newSuperAdminData.value.name['last'] = ''
        newSuperAdminData.value.email= ''
        newSuperAdminData.value.phone= ''
      })
    } catch (error) {
      console.error('Error creating super admin:', error);
      notify(
        {
          group: "error",
          title: 'Error',
          text: error?.response?.data?.message,
        },
        2000
      );
    }
  }

  const setCurrentSuperAdmin = async (id) =>{
    const {currentUser} = storeToRefs(useUserStore())
    try {
      const res = await axiosAuth.get(`${root}/super-admin/${id}`)
      .then((res) =>{
       currentUser.value=res.data
       useCookie('userData').value = currentUser.value;
       useCookie('intercomUser').value = null
       useCookie('intercomHash').value = null

      })
    } catch (error) {
      console.error(error);
    }
    useCookie('userAbilityRules').value = await getUserAbilities(currentUser.value)
  }

  const getCompanyLogin = async (id) => {
    const { setCurrentUser , check_intercom_user} = useUserStore()
      const {getAdminSettings} = useAdmin()
      const {getNotificationList} = useNotification()
    const {setToken} = useAuth()  
    try {
      const res = await axiosAuth.get(`${root}/profile/switch-account/${id}`);
      setToken(
        res.data.access_token,
        res.data.refresh_token,
        jwtDecode(res.data.access_token).exp * 1000
      )
      const userToken = await jwt_decode(res.data.access_token);
      const profile = userToken.profile;

      await getAdminSettings()
      await setCurrentUser(profile);
      await check_intercom_user(profile)
      // console.log('-------------------------------------->Notifications')
      await getNotificationList();

      notify(
            {
                group: "success",
                title: 'Login',
                text: 'Logged in successfully as admin',
            },
            2000
        );
    } catch (error) {
        console.error('Error logging in company:', error);
        let status_code = error.response.status
        if(status_code === 404 ){
        notify(
            {
                group: "success",
                title: 'Company Login',
                text: 'Company creation in progress. Please wait.',
            },
            2000
        );
        } else {
        notify(
            {
                group: "error",
                title: 'Error',
                text: `Something went wrong during creation`,
            },
            2000
        );
    }
    }
  };
  const getSuperAdminLogin = async () => {
    const {setToken} = useAuth()
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
    try {
      const res = await axiosAuth.get(`${root}/profile/switch-account/`);
      setToken(
        res.data.access_token,
        res.data.refresh_token,
        jwt_decode(res.data.access_token).exp * 1000
      )
      const userToken = await jwt_decode(res.data.access_token);
      const profile = userToken.profile;
      await setCurrentSuperAdmin(profile);
    } catch (error) {
      console.error('Error logging in company:', error);
      notify(
          {
            group: "error",
            title: 'Error',
            text: "Not Able to switch",
          },
          2000
      );
    }
  };

  const jobStats = async () => {
      const todayStartTime = getTodayWithStartTime()
      const todayEndTime = getTodayEndTime()
      await axiosAuth
        .get(`${root}/projects/jobs_stats`, { params: {today_date: todayStartTime, today_date_end: todayEndTime} })
        .then((res) => {
          jobsStatsData.value = res.data
        })
        .catch((err) => {
          console.log(err)
        })
  }

  const companyStatsAndLeaders = async () => {
      const todayStartTime = getTodayWithStartTime()
      const TimeNow = new Date()
      await axiosAuth
        .get(`${root}/projects/stats_and_leaders`, { params: {today_date: todayStartTime, time_now: TimeNow} })
        .then((res) => {
          graphStats.value = res.data.monthly_payments_stats
          lestYearGraphStats.value = res.data.last_year_payments_stats
        })
        .catch((err) => {
          console.log(err)
        })
  }

  const companySalesRepLeader = async (filter) => {
      const todayStartTime = getTodayWithStartTime()
      const TimeNow = new Date()
      await axiosAuth
        .get(`${root}/projects/sales_rep_leaders`, { params: {today_date: todayStartTime, time_now: TimeNow, filter: filter} })
        .then((res) => {
          statsAndLeaders.value = res.data.sales_rep_leaders
        })
        .catch((err) => {
          console.log(err)
        })
  }


  const companyTopReferralSource = async (filter) => {
      const todayStartTime = getTodayWithStartTime()
      const TimeNow = new Date()
      await axiosAuth
        .get(`${root}/projects/referral_source`, { params: {today_date: todayStartTime, time_now: TimeNow, filter: filter} })
        .then((res) => {
            topReferralSource.value = res.data.top_referral_source
        })
        .catch((err) => {
          console.log(err)
        })
  }

  const leadToday = async () => {
      const todayStartTime = getTodayWithStartTime()
      await axiosAuth
        .get(`${root}/clients/leads_today`, { params: {today_date: todayStartTime} })
        .then((res) => {
          clientAddedToday.value = res.data
        })
        .catch((err) => {
          console.log(err)
        })
  }

  const accountSetup = async (credentials) => {
    const root = `${useRuntimeStore().$state.apiRoot}`
    const formData = {
      username: credentials
    }

    await axios
      .post(`${root}/super-admin/account-setup`, formData)
      .then(() => {
        console.log('Email Sent!')
        notify(
          {
              group: "success",
              title: 'Email sent',
              text: 'Account setup mail has been sent to user.',
          },
          2000
      );
      })
      .catch((error) => {
        console.log(error)
        notify(
          {
            group: "error",
            title: 'Mail not sent',
            text: "There is issue in sending mail for the account setup.",
          },
          2000
      );
      })
  }

  
  const value = {
    superAdminsList,
    isLoading,
    jobsStatsData,
    statsAndLeaders,
    graphStats,
    lestYearGraphStats,
    newSuperAdminData,
    total_count,
    topReferralSource,
    clientAddedToday,
  };

  const actions = {
    getSuperAdminList,
    createSuperAdmin,
    setCurrentSuperAdmin,
    getCompanyLogin,
    getSuperAdminLogin,
    jobStats,
    companyStatsAndLeaders,
    leadToday,
    accountSetup,
    companySalesRepLeader,
    companyTopReferralSource
  };

  return {
    ...value, 
    ...actions, 
  };
});
