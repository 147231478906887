/**
 * This is helper function to register plugins like a nuxt
 * To register a plugin just export a const function `defineVuePlugin` that takes `app` as argument and call `app.use`
 * For Scanning plugins it will include all files in `src/plugins` and `src/plugins/**\/index.ts`
 *
 *
 * @param {App} app Vue app instance
 * @returns void
 *
 * @example
 * ```ts
 * // File: src/plugins/vuetify/index.ts
 *
 * import type { App } from 'vue'
 * import { createVuetify } from 'vuetify'
 *
 * const vuetify = createVuetify({ ... })
 *
 * export default function (app: App) {
 *   app.use(vuetify)
 * }
 * ```
 *
 * All you have to do is use this helper function in `main.ts` file like below:
 * ```ts
 * // File: src/main.ts
 * import { registerPlugins } from '@core/utils/plugins'
 * import { createApp } from 'vue'
 * import App from '@/App.vue'
 *
 * // Create vue app
 * const app = createApp(App)
 *
 * // Register plugins
 * registerPlugins(app) // [!code focus]
 *
 * // Mount vue app
 * app.mount('#app')
 * ```
 */
import Notiwind from 'notiwind'
import {useRuntimeStore} from "@/stores/runtimeStore.js";
import config from '/config.json'

export const registerPlugins = app => {
  const imports = import.meta.glob(['../../plugins/*.{ts,js}', '../../plugins/*/index.{ts,js}'], { eager: true })
  const importPaths = Object.keys(imports).sort()

  importPaths.forEach(path => {
    const pluginImportModule = imports[path]
    app.use(Notiwind)
    pluginImportModule.default?.(app)
  })
}

export const configSetup =  () =>{
  const runtimeConfig = {}
  const environment = import.meta.env.MODE
  runtimeConfig.apiRoot = config[environment].apiRoot
  runtimeConfig.cookie_secret=config[environment].cookie_secret
  runtimeConfig.geocodeApi = config[environment].geocodeApi
  runtimeConfig.recurringServicesEnabled = config[environment].recurringServicesEnabled
  runtimeConfig.mapboxToken = config[environment].mapboxToken
  runtimeConfig.options = config[environment].initOptions
  runtimeConfig.intercom_app_id = config[environment].intercom_app_id
  const runtimeStore = useRuntimeStore()
  runtimeStore.$state = runtimeConfig
}
