import { createMongoAbility } from '@casl/ability'
import { abilitiesPlugin } from '@casl/vue'
import {useCookie} from "@core/composable/useCookie.js";

export default function (app) {
  const userAbilityRules = useCookie('userAbilityRules').value
  const initialAbility = createMongoAbility(userAbilityRules ?? [{}])
  app.use(abilitiesPlugin, initialAbility, {
    useGlobalProperties: true,
  })
}
