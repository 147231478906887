// const emailRouteComponent = () => import('@/pages/apps/email/index.vue')


// 👉 Redirects
export const redirects = [
  // ℹ️ We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    name: 'index',
    redirect: to => {
      // TODO: Get type from backend
      const userData = useCookie('userData').value
      const userRole = userData?.roles
      if (userRole?.includes('superadmin'))
      return { name: 'dashboards-logistics' }
     else if (userRole?.includes('admin') || userRole?.includes('arborist'))
        return { name: 'admin-dashboard' }
     else if (userRole?.includes('crew') || userRole?.includes('crew_leader'))
        return { name: 'crew-dispatch-list' }
      else
      return { name: 'login', query: to.query }
    },
  },
  {
    path: '/pages/user-profile',
    name: 'pages-user-profile',
    redirect: () => ({ name: 'pages-user-profile-tab', params: { tab: 'profile' } }),
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    redirect: () => ({ name: 'pages-account-settings-tab', params: { tab: 'account' } }),
  },


]
export const routes = [
  {
    path: '/company/:id/:name',
    name: 'company-id',
    component: () => import('@/pages/apps/company/[id].vue'),
    meta: {  action: ['manage'],
      subject: 'company',}
  },
  {
    path: '/dashboard',
    name: 'dashboards-logistics',
    component: () => import('@/pages/apps/logistics/dashboard.vue'),
     meta: {  action: ['manage'],
    subject: 'dashboard',}
  },
  {
    path: '/dashboard',
    name: 'admin-dashboard',
    component: () => import('@/pages/apps/superadmin/dashboard/index.vue'),
     meta: {  action: ['manage'],
    subject: 'admin-dashboard',}
  },
  {
    path: '/companies',
    name: 'company-list',
    component: () => import('@/pages/apps/company/index.vue'),
     meta: {  action: ['manage'],
    subject: 'dashboard',}
  },
  {
    path: '/dashboards/academy',
    name: 'dashboards-academy',
    component: () => import('@/pages/apps/academy/dashboard.vue'),
  },


  //  Main Routes starts from Here...............................................
  {
    path: '/estimates',
    name: 'estimate-list',
    component: () => import('@/pages/apps/estimate/estimates.vue'),
    meta: {  action: ['manage'],
    subject: 'estimates',}
  },
  {
    path: '/apps/jobs/Preview',
    name: 'apps-jobs-preview',
    component: () => import('@/pages/apps/jobs/Preview.vue'),
    meta: {  action: ['manage'],
    subject: 'estimates',}
  },
  {
    path: '/customer-portal',
    name: 'customer-portal',
    component: () => import('@/pages/apps/customer/CustomerPortal.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },

  {
    path: '/dispatch/:tab',
    name: 'dispatch-list-tab',
    component: () => import('@/pages/apps/dispatch/overview.vue'),
    meta: {  action: 'manage',
    subject: 'dispatch',}
  },
{
    path: '/job/:jobId/dispatch/:dispatchId',
    name: 'apps-dispatch-edit-id',
    component: () => import('@/pages/apps/jobs/Dispatches/edit/[id].vue'),
    meta: {  action: 'manage',
    subject: 'dispatch',}
  },

  {
    path: '/dispatch/calendar',
    name: 'dispatch-calendar',
    component: () => import('@/pages/apps/dispatch/calendar.vue'),
    meta: {  action: 'manage',
    subject: 'dispatch',}
  },
  {
    path: '/dispatch/map',
    name: 'dispatch-map',
    component: () => import('@/pages/apps/dispatch/Map.vue'),
    meta: {  action: 'manage',
    subject: 'dispatch',}
  },

          {
    path: '/dispatch/date_dispatch',
    name: 'dispatch-calendar-date',
    component: () => import('@/pages/apps/dispatch/DateDispatch.vue'),
    meta: {  action: 'manage',
    subject: 'dispatch',}
  },
  {
    path: '/job/:jobId/dispatch/new',
    name: 'apps-dispatch-new',
    component: () => import('@/pages/apps/jobs/Dispatches/new/[id].vue'),
    meta: {  action: 'manage',
    subject: 'dispatch',}
  },

  {
    path: '/dispatch/new',
    name: 'dispatch-new',
    component: () => import('@/pages/apps/jobs/Dispatches/DispatchModal.vue'),
    meta: {  action: 'manage',
    subject: 'contacts',}
  },

  {
    path: '/crew/dispatch',
    name: 'crew-dispatch-list',
    component: () => import('@/pages/apps/crews/dispatch/list.vue'),
    meta: {  action: 'manage',
    subject: 'crew-dispatch',}
  },

  {
    path: '/job-manager/job/:id/dispatch/:dispatch_id',
    name: 'complete-dispatch-id',
    component: () => import('@/pages/apps/crews/dispatch/completeDispatch.vue'),
    meta: {  action: 'manage',
    subject: 'crew-dispatch',}
  },

  {
    path: '/billing',
    name: 'billing-list',
    component: () => import('@/pages/apps/billing/list.vue'),
    meta: {  action: 'manage',
    subject: 'billing',}
  },
  {
    path: '/billing/:id',
    name: 'billing-id',
    component: () => import('@/pages/apps/billing/[id].vue'),
    meta: {  action: 'manage',
    subject: 'billing',}
  },
{
    path: '/billing',
    name: 'billing-overview',
    component: () => import('@/pages/apps/billing/list.vue'),
    meta: {  action: 'manage',
    subject: 'billing',}
  },
  {
    path: '/billing/messages/:id',
    name: 'billing-messages',
    component: () => import('@/pages/apps/billing/messages.vue'),
    meta: {  action: 'manage',
    subject: 'billing',}
  },
  
  {
    path: '/job/:id',
    name: 'jobs-view-id', 
    component: () => import('@/pages/apps/jobs/view/[id].vue'),
    meta: {  action: 'manage',
    subject: 'estimates',},
},
  {
    path: '/psm',
    name: 'psm',
    component: () => import('@/pages/apps/psm/index.vue'),
    meta: {  action: 'manage',
    subject: 'psm',}
  },
    {
        path: '/inventory',
        name: 'inventory',
        component: () => import('@/pages/apps/inventory/index.vue'),
        meta: {  action: 'manage',
            subject: 'psm',}
    },

  {
    path: '/clients',
    name: 'clients',
    component: () => import('@/pages/apps/clients/clients.vue'),
    meta: {  action: 'manage',
    subject: 'clients',}
  },
 
  {
    path: '/client/:id',
    name: 'clients-client-view-id',
    component: () => import('@/pages/apps/clients/client_view/[id].vue'),
    meta: {  action: 'manage',
    subject: 'clients',}
  },
  {
    path: '/client/:client_id/service-address/:id',
    name: 'client-service-address',
    component: () => import('@/pages/apps/clients/client_view/ServiceAddressView.vue'),
    meta: {  action: 'manage',
      subject: 'clients',}
  },
  {
    path: '/crew/calendar/:id',
    name: 'apps-crew-calendar-view-id',
    component: () => import('@/pages/apps/crews/crewCalendar/[id].vue'),
    meta: {  action: 'manage',
    subject: 'clients',}
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('@/pages/apps/contact/index.vue'),
    meta: {  action: 'manage',
    subject: 'contacts',}
  },

  {
    path: '/crews',
    name: 'crews',
    component: () => import('@/pages/apps/crews/crew.vue'),
    meta: {  action: 'manage',
    subject: 'crews',}
  },
  {
    path: '/crew/:id',
    name: 'crew-id-dispatch',
    component: () => import('@/pages/apps/crews/crew_view/[id].vue'),
    meta: {  action: 'manage',
    subject: 'crews',}
  },

  {
    path: '/profiles',
    name: 'profiles',
    component: () => import('@/pages/apps/profiles/index.vue'),
    meta: {  action: 'manage',
    subject: 'profiles',}
  },
  {
    path: '/profile/:id',
    name: 'apps-profiles-edit-id',
    component: () => import('@/pages/apps/profiles/edit/[id].vue'),
    meta: {  action: 'manage',
    subject: 'profiles',}
  },
  {
    path: '/profile/:id',
    name: 'apps-profiles-view-id',
    component: () => import('@/pages/apps/profiles/view/[id].vue'),
    meta: {  action: 'manage',
    subject: 'profiles',}
  },
  {
    path: '/:tab',
    name: 'pages-account-settings-tab',
    component: () => import('@/pages/pages/account-settings/[tab].vue'),
    meta: {  action: 'manage',
    subject: 'account-settings',}
  },
  {
    path: '/superadmins',
    name: 'superadmin',
    component: () => import('@/pages/apps/superadmin/SuperAdminList.vue'),
    meta: {  action: 'manage',
    subject: 'dashboard',}
  },

  {
    path: '/company/company-setting',
    name: 'apps-company-company-setting',
    component: () => import('@/pages/apps/company/CompanySetting.vue'),
     meta: {  action: 'manage',
    subject: 'company-settings',}
  },

  {
    path: '/company/payment-gateway',
    name: 'apps-company-payment-gateway',
    component: () => import('@/pages/apps/company/PaymentGateway.vue'),
     meta: {  action: 'manage',
    subject: 'company-settings',}
  },
  {
    path: '/company/portal-customization',
    name: 'apps-company-portal-customization',
    component: () => import('@/pages/apps/company/PortalCustomizationSettings.vue'),
    meta: {  action: ['manage'],
    subject: 'company-settings',}
  },
  {
    path: '/transactions-report',
    name: 'apps-company-transactions-report',
    component: () => import('@/pages/apps/company/TransactionsReportList.vue'),
    meta: {  action: ['manage'],
    subject: 'company-settings',}
  },
   {
    path: '/settings',
    name: 'apps-settings-settings',
    component: () => import('@/pages/apps/settings/Settings.vue'),
     meta: {  action: 'manage',
    subject: 'company-settings',}
  },
 {
    path: '/leads',
    name: 'apps-settings-leads',
    component: () => import('@/pages/apps/settings/Leads.vue'),
     meta: {  action: 'manage',
    subject: 'company-settings',}
  },
 {
    path: '/payments',
    name: 'apps-settings-payments',
    component: () => import('@/pages/apps/settings/Payments.vue'),
     meta: {  action: 'manage',
    subject: 'company-settings',}
  },
 {
    path: '/company_branding',
    name: 'apps-settings-company-branding',
    component: () => import('@/pages/apps/settings/CompanyBranding.vue'),
     meta: {  action: 'manage',
    subject: 'company-settings',}
  },
  {
    path: '/communication',
    name: 'apps-settings-communication',
    component: () => import('@/pages/apps/settings/Communication.vue'),
     meta: {  action: 'manage',
    subject: 'company-settings',}
  },
   {
    path: '/email-template',
    name: 'email-template',
    component: () => import('@/pages/apps/email/MailTemplate.vue'),
     meta: {  action: 'manage',
    subject: 'company-settings',}
  },

  {
    path: '/tasks',
    name: 'all-tasks',
    component: () => import('@/pages/apps/tasks/allTasks.vue'),
     meta: {  action: 'manage',
    subject: 'tasks',}
  },
  {
    path: '/myTasks',
    name: 'my-tasks',
    component: () => import('@/pages/apps/tasks/myTasks.vue'),
     meta: {  action: 'manage',
    subject: 'my-tasks',}
  },
  {
    path: '/othersTasks',
    name: 'others-tasks',
    component: () => import('@/pages/apps/tasks/othersTasks.vue'),
     meta: {  action: 'manage',
    subject: 'tasks',}
  },
      {
    path: '/schedules',
    name: 'office-calendar',
    component: () => import('@/pages/apps/schedules/officeCalendar.vue'),
     meta: {  action: 'manage',
    subject: 'schedules',}
  },
  {
    path: '/crew_report',
    name: 'crew-report',
    component: () => import('@/pages/apps/reports/crewReport.vue'),
     meta: {  action: 'manage',
    subject: 'reports',}
  },
  {
    path: '/accounts_report',
    name: 'accounts-report',
    component: () => import('@/pages/apps/reports/accountsReport.vue'),
    meta: {  action: 'manage',
    subject: 'reports',}
  },
  {
    path: '/backlog_report',
    name: 'backlog-report',
    component: () => import('@/pages/apps/reports/backlogReport.vue'),
    meta: {  action: 'manage',
    subject: 'reports',}
  },
     {
    path: '/customization',
    name: 'apps-settings-customization',
    component: () => import('@/pages/apps/settings/Customization.vue'),
     meta: {  action: 'manage',
    subject: 'company-settings',}
  },
    {
        path: '/notifications',
        name: 'notification',
        component: () => import('@/@core/components/VIewAllNotification.vue'),
        meta: {  action: 'manage',
            subject: 'notifications',}
    },

]
