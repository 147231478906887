import { defineStore } from 'pinia'
export const useRuntimeStore = defineStore({
  id: 'runtimeStore',
  state: () => ({
    apiRoot: "https://mantxapi.moveitpro.dev/api",
    options: {
      url: "https://keycloakadmin.moveitpro.dev",
      realm: "python-api",
      clientId: "python-api",
      onload: "login-required",
      scope: "openId",
      client_secret: "HJ2RVQbkeqRYCYMQ7Tx1bz0PCpQwKint"
    },
    clearentEnabled: import.meta.env.VITE_APP_CLEARENT_URL,
    clearentUrl: import.meta.env.VITE_APP_CLEARENT_URL,
    clearentMid: import.meta.env.VITE_APP_CLEARENT_MID,
    clearentTid: import.meta.env.VITE_APP_CLEARENT_TID,
    intercom_app_id : '',
    mapboxToken : '',
    // recurringServicesEnabled: import.meta.env
    //   .VITE_APP_RECURRING_SERVICES_ENABLED
    //hardcoded recurringServicesEnabled value
    recurringServicesEnabled: true
  })
})
