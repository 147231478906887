// import router from '@/router'
import { ref, computed } from 'vue'
import jwt_decode from 'jwt-decode'
import axios from 'axios'
import {router } from '@/plugins/1.router/index'
import { notify } from 'notiwind'
// Pinia
import { defineStore } from 'pinia'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/userStore'
import { useRuntimeStore } from '@/stores/runtimeStore'
import { useCookie } from '@/@core/composable/useCookie'
import { useSuperAdmin } from './superAdminStore'
import axiosAuth from "@/utils/AxiosAuth.js";
import { useCompany } from './companyStore'
import Intercom from '@intercom/messenger-js-sdk';
export const useAuth = defineStore('authStore', () => {
  const accessToken = ref(localStorage.getItem('accessToken') || null)
  const refreshToken = ref(localStorage.getItem('refreshToken') || null)
  const tokenExpiresAt = ref(null)
  const tokenRefreshTimeoutId = ref(null)
  const isLoading = ref(false)
  const invalidCredentials = ref(false)

  const isAuthenticated = computed(() => {
    return checkRefreshToken()
  })
  const router = useRouter()
  const ability = useAbility()

  const clearToken = () => {
    accessToken.value = null
    tokenExpiresAt.value = null
    tokenRefreshTimeoutId.value = null
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    useCookie('userAbilityRules').value = null
    useCookie('userData').value = null
    useCookie('accessToken').value = null
    useCookie('intercomUser').value = null
    useCookie('intercomHash').value = null
    ability.update([])
  }

  const shutdownIntercom = () =>{
    if (window.Intercom) {
      window.Intercom('shutdown');
    } else {
      console.error('Intercom is not loaded.');
    }
  }

  const checkRefreshToken = () => {
    try {
      if (refreshToken.value) {
        const decodedRefreshToken = jwt_decode(refreshToken.value)
        const currentTime = Math.floor(Date.now() / 1000)
        if (currentTime > decodedRefreshToken.exp) {
          console.log('Refresh token expired')
          logout()
          return false
        } else {
          console.log('refresh token still valid')
          return true
        }
      }
    } catch (error) {
      console.log('Error checking refresh token:', error.message)
    }
  }
const getUsers = async (username) => {
  if(username == ''){
    return
  }
  const root = `${useRuntimeStore().$state.apiRoot}`;
  try {
    const response = await axiosAuth.get(`${root}/profile/get-users/${username}`);
    return response.data;
  } catch (err) {
    console.log("Error retrieving users:", err);
  }
};



  const refreshAccessToken = async () => {
    const { setCurrentUser } = useUserStore()
    const {setCurrentSuperAdmin} = useSuperAdmin();
    const root = `${useRuntimeStore().$state.apiRoot}/auth`

    const formData = {
      refresh_token: refreshToken.value,
    }

    const tokenIsValid = await checkRefreshToken()
    console.log('tokenIsValid', tokenIsValid)
    if (!tokenIsValid || !refreshToken.value) {
      console.log('Refresh token is invalid or expired. Logging out.')
    } else {
      return axios
        .post(
          `${root}/refresh_token`,
          formData,
        )
        .then(async (res) => {
          setToken(
            res.data.access_token,
            res.data.refresh_token,
            jwt_decode(res.data.access_token).exp * 1000
          )
          const userToken = await jwt_decode(res.data.access_token)
          if(!userToken.realm_access.roles.includes('superadmin')){
            await setCurrentUser(userToken.profile);
          }
          else{
            await setCurrentSuperAdmin(userToken.profile)
          }
          return res
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log('Refresh token is invalid or expired. Logging out.')
            logout()
          } else {
            console.log('Error refreshing token:', error.message)
          }
          throw error
        })
    }
  }

  const setToken = (access, refresh, expiresAt) => {
    accessToken.value = access
    refreshToken.value = refresh
    localStorage.setItem('accessToken', access)
    localStorage.setItem('refreshToken', refresh)
    tokenExpiresAt.value = expiresAt
  }

  const forgetPassword = async (credentials) => {
    const root = `${useRuntimeStore().$state.apiRoot}`
    const formData = {
         username: credentials.username,
    };

    await axios
      .post(`${root}/profile/forgot-password`,formData
    )
      .then(() => {
        console.log("Email Sent!")
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const userLogin = async (credentials) => {
  const { setCurrentUser ,check_intercom_user} = useUserStore();
  const {setCurrentSuperAdmin} = useSuperAdmin();
  const { updateCompany} =  useCompany()
    const root = `${useRuntimeStore().$state.apiRoot}/auth`
  const formData ={
    username: credentials.username,
    password: credentials.password,
  }
    isLoading.value = true
  try {
    const res = await axios.post(
      `${root}/login`,
      formData,
    );
    setToken(
      res.data.access_token,
      res.data.refresh_token,
      jwt_decode(res.data.access_token).exp * 1000
    );
    const userToken = await jwt_decode(res.data.access_token);
    const profile = userToken.profile;
    useCookie('accessToken').value = localStorage.getItem('accessToken');
    if(!userToken.realm_access.roles.includes('superadmin')){
      await setCurrentUser(profile);
      await check_intercom_user(profile);
      await updateCompany(userToken.company)
    }
    else{
      await setCurrentSuperAdmin(profile)
    }
    isLoading.value = false
  } catch (err) {
    console.log(err);
    invalidCredentials.value = true
    notify(
      {
        group: 'error',
        title: 'Error',
        text: 'Please check your credentials and try again.'
      },
      2000
    );
  }
}

  const logout = async () => {
    const root = `${useRuntimeStore().$state.apiRoot}/auth`
    const formData = {
      refresh_token: refreshToken.value,
    }
    await axios
      .post(
        `${root}/logout`,
        formData
      )
      .then(() => {
        clearToken()
        shutdownIntercom()
        router.push('/login')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const values = {
    accessToken,
    tokenExpiresAt,
    tokenRefreshTimeoutId,
    isAuthenticated,
    isLoading,
    invalidCredentials
  }

  const actions = {
    setToken,
    clearToken,
    shutdownIntercom,
    userLogin,
    refreshAccessToken,
    logout,
    forgetPassword,getUsers
  }

  return {
    ...values,
    ...actions
  }
})