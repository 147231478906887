import axiosAuth from '@/utils/AxiosAuth'
import { useRuntimeStore } from '@/stores/runtimeStore'
import { notify } from 'notiwind'
import {useCookie} from "@core/composable/useCookie.js";

// const root = useRuntimeStore().$state.apiRoot

export const fetchProfileById = async (id) => {
    const root = useRuntimeStore().$state.apiRoot
  let result
    let url = `${root}/profile/${id}/`
    if (useCookie('userData').value .roles.includes('superadmin')){
        url = `${root}/super-admin/${id}`
    }
  await axiosAuth
    .get(`${url}`)
    .then((res) => {
      result = res.data
    })
    .catch((err) => {
      console.log(err)
      notify(
        {
          group: 'error',
          title: 'Error Loading Profile',
          text: 'There was a problem loading this profile. Please try refreshing the page.'
        },
        2000
      )
    })
  return result
}

export const updateProfile = async (editedProfile) => {
  const root = useRuntimeStore().$state.apiRoot
  let profileId = editedProfile.id
  let result
  await axiosAuth
    .put(`${root}/profile/${profileId}/`, editedProfile)
    .then((res) => {
      result = res.data
      notify(
        {
          group: 'success',
          title: 'Profile Updated',
          text: 'Profile updated successfully.'
        },
        2000
      )
    })
    .catch((err) => {
      console.log(err)
      notify(
        {
          group: 'error',
          title: 'Error Saving Profile',
          text: 'We had a problem saving this profile.'
        },
        2000
      )
    })
  return result
}

export  const updateSuperAdminProfile = async (editedProfile) =>{
  const root = useRuntimeStore().$state.apiRoot
  let profileId = editedProfile.id
  let result
  await axiosAuth
    .put(`${root}/super-admin/${profileId}/`, editedProfile)
    .then((res) => {
      result = res.data
      notify(
        {
          group: 'success',
          title: 'Profile Updated',
          text: 'Profile updated successfully.'
        },
        2000
      )
    })
    .catch((err) => {
      console.log(err)
      notify(
        {
          group: 'error',
          title: 'Error Saving Profile',
          text: 'We had a problem saving this profile.'
        },
        2000
      )
    })
  return result
}

export const postProfile = async (newProfile) => {
  const root = useRuntimeStore().$state.apiRoot
  let result
  await axiosAuth
    .post(`${root}/profile/`, newProfile)
    .then((res) => {
      result = res.data
      notify(
        {
          group: 'success',
          title: 'Profile Created',
          text: 'Profile created successfully.'
        },
        2000
      )
    })
    .catch((err) => {
      console.log(err)
      notify(
        {
          group: 'error',
          title: 'Error Creating Profile',
          text: 'We had a problem creating this profile.'
        },
        2000
      )
    })
  return result
}

export const postNewProfileImage = async (profileId, data) => {
  // this is for uploading a new profile image for an existing profile
  const root = useRuntimeStore().$state.apiRoot
  let result
  await axiosAuth
    .post(`${root}/profile/${profileId}/profile_image`, data)
    .then((res) => {
      result = res.data
    })
    .catch((err) => {
      console.log(err)
      notify(
        {
          group: 'error',
          title: 'Error Saving Profile Image',
          text: 'We were unable to save this profile image. Please try again.'
        },
        2000
      )
    })
  // returns an object with an id and url
  return result
}
