// import router from '@/router'
import { ref, computed } from 'vue'
import axiosAuth from '@/utils/AxiosAuth'
import { defineStore } from 'pinia'
import { useJobStore } from '@/stores/jobStore'
import { storeToRefs } from 'pinia'
import { useRuntimeStore } from '@/stores/runtimeStore'
// Composable
// import { useToggle } from '@/composables'
import { notify } from 'notiwind'

// import { useUserStore } from './userStore'
// import { useAttach } from './attachmentStore'
// import { notify } from 'notiwind'
// import router from '@/router'

export const useGeoJson = defineStore('geoJsonStore', () => {
  const geoJson = ref({
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: []
    }
  })

  const geoData = ref({
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: []
    }
  })

  const searchRadius = ref(5)
  const fetchingFeatures = ref(false)
  const selectedStatusOptions = ref([])
  const selectedCrews = ref([])
  const startDate = ref(null)
  const endDate = ref(null)
  const mapCenter = ref(null)
  const zoomLevel = ref(13)
  const userPosition = ref(null)
  const markersData = ref([])
  const selectedFeature = ref(null)
  const mapInstance = ref(null)
  const mapLoaded = ref(false)
  const geoCoder = ref(null)
  const mapModal = ref(useToggle(false))
  const features = ref([])
  const jobFeature = ref(null)
  const selectedUrgencies = ref([])
  const locationEnabled = ref(false)

  const clickThreshold = computed(() => {
    let result = 0.035
    if (zoomLevel.value < 10) {
      result = 0.4
    }
    return result
  })

  const mapSearchParams = computed({
    get() {
      return {
        limit: 100,
        status: 'dispatch',
        active: true,
        radius: searchRadius.value,
        longitude: mapCenter.value[0] ?? null,
        latitude: mapCenter.value[1] ?? null,
        ...(startDate.value ? { start_date: startDate.value } : {}),
        ...(endDate.value ? { end_date: endDate.value } : {})
      }
    },
    set: (val) => {
      return {
        limit: val.limit,
        status: 'dispatch',
        active: val.active,
        radius: searchRadius.value,
        longitude: mapCenter.value[0] ?? null,
        latitude: mapCenter.value[1] ?? null,
        ...(startDate.value ? { start_date: startDate.value } : {}),
        ...(endDate.value ? { end_date: endDate.value } : {})
      }
    }
  })

  const formattedCrewIds = computed(() => {
    if (selectedCrews.value.length === 0) return
    return selectedCrews.value.map((crew, i) => {
      if (i !== selectedCrews.value.length - 1) {
        return `crew_ids=${crew.id}&`
      }
      return `crew_ids=${crew.id}`
    })
  })

  const formattedUrgencies = computed(() => {
    if (selectedUrgencies.value.length === 0) return ''
    return selectedUrgencies.value
      .map((urgency, i) => {
        if (i !== selectedUrgencies.value.length - 1) {
          return `urgencies=${urgency}&`
        }
        return `urgencies=${urgency}`
      })
      .join('')
  })

  const queryString = computed(() => {
    if (!formattedCrewIds.value && !formattedUrgencies.value) return
    if (!formattedCrewIds.value && formattedUrgencies.value) {
      return `?${formattedUrgencies.value}`
    }
    if (formattedCrewIds.value && !formattedUrgencies.value) {
      console.log(formattedCrewIds.value,"<<<<<<<< query strings >>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
      return `?${formattedCrewIds.value}`
    }
    return `?${formattedCrewIds.value}&${formattedUrgencies.value}`
  })

  // const checkItemCompletion = (item) => {
  //   if (
  //     !item.unfinished_product_line_items &&
  //     !item.unfinished_service_line_items
  //   ) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  const getFeatureById = async (id) => {
    const root = useRuntimeStore().$state.apiRoot
    await axiosAuth
      .get(`${root}/projects/${id}/`)
      .then((res) => {
        jobFeature.value = res.data
      })
      .catch((err) => {
        if (err.response && err.response.status == 500) {
          console.log(err.response)
          notify({
            group: 'error',
            title: 'Error Loading Jobs',
            text: 'There was a problem loading projects.'
          })
        }
      })
  }

  const getFeatureStatus = (project) => {
    const isActive =
      project.dispatches.length === 0 ||
      project.dispatches.every((obj) => obj.status === 'complete')

    const isScheduled =
      project.dispatches.length !== 0 &&
      project.dispatches.every((obj) => obj.status !== 'complete') &&
      project.dispatches.every((obj) => obj.status !== 'in_progress')

    const isDispatch =
      project.dispatches.length !== 0 &&
      project.dispatches.some((obj) => obj.status === 'in_progress')
    // Reset mapStatus to ensure it's always updated correctly
    project.mapStatus = null // Default state, in case none of the conditions below apply

    // Given the priority, check isActive first
    if (isActive) {
      project.mapStatus = 'active'
    } else if (isScheduled) {
      // If not active, but isDispatch is true, it means there's at least one in_progress, set to scheduled
      project.mapStatus = 'scheduled'
    } else if (isDispatch) {
      // If not active or scheduled but isPending is true, it means there's at least one pending
      project.mapStatus = 'dispatch'
    }
  }

  const getGeoData = async () => {
    fetchingFeatures.value = true
    const { getJobs } = useJobStore()
    const { projects } = storeToRefs(useJobStore())
    projects.value = []
     await getJobs(mapSearchParams.value, queryString.value)
    // await getJobs()
    let newFeatures = projects.value.map((project) => {
      getFeatureStatus(project)
      let crews = project.dispatches.map((dispatch) => {
        return dispatch.crew
      })
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [
             project?.service_address?.address?.coordinates?  project?.service_address?.address?.coordinates[0] :  project?.service_address?.coordinates[0],
             project?.service_address?.address?.coordinates?  project?.service_address?.address?.coordinates[1] :  project?.service_address?.coordinates[1],
          ]
        },
        properties: {
          dispatches: project.dispatches,
          mapStatus: project.mapStatus,
          // service_address: project.service_address,
          status: project.status,
          crews: crews,
          project: project,
          urgency: project.urgency
        }
      }
    })

    // Call project search with params
    // Loop through the response and push the features to
    // a temporary array
    // Set the geoData features to the temporary array
    geoData.value.data.features = newFeatures
    features.value = newFeatures
    fetchingFeatures.value = false
  }

  const mapDispatchItems = computed(() => {
    return jobFeature.value.dispatches.reduce((acc, dispatch) => {
      dispatch.product_line_items.map((product) => {
        acc.push(product)
      })
      dispatch.service_line_items.map((service) => {
        acc.push(service)
      })
      return acc
    }, [])
  })

  const getUserPosition = () => {
              // console.log("-----position----",position)

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          userPosition.value = [
            position.coords.longitude,
            position.coords.latitude
          ]
          locationEnabled.value = true
        },
        (error) => {
          locationEnabled.value = false
          console.log(error)
        }
      )
    }
  }

  const values = {
    mapCenter,
    searchRadius,
    geoData,
    fetchingFeatures,
    mapSearchParams,
    zoomLevel,
    selectedCrews,
    geoJson,
    userPosition,
    markersData,
    features,
    startDate,
    endDate,
    selectedFeature,
    mapInstance,
    mapLoaded,
    geoCoder,
    mapModal,
    jobFeature,
    mapDispatchItems,
    selectedStatusOptions,
    selectedUrgencies,
    formattedUrgencies,
    locationEnabled,
    clickThreshold
  }

  const actions = {
    getGeoData,
    getFeatureById,
    getFeatureStatus,
    getUserPosition
  }

  return {
    ...values,
    ...actions
  }
})
