// Ported from [Nuxt](https://github.com/nuxt/nuxt/blob/main/packages/nuxt/src/app/composables/cookie.ts)
import { parse, serialize } from 'cookie-es'
import { destr } from 'destr'
import CryptoJS from 'crypto-js'
import { useRuntimeStore } from '@/stores/runtimeStore'


const CookieDefaults = {
  path: '/',
  watch: true,
  decode: val => decrypt(decodeURIComponent(val)),
  encode: val => encodeURIComponent(encrypt(typeof val === 'string' ? val : JSON.stringify(val))),
}

export const useCookie = (name, _opts = null) => {
  const opts = { ...CookieDefaults, ...(_opts || { secure: true }) };
  const cookies = parse(document.cookie, opts);
  const cookie = ref(cookies[name] ? destr(cookies[name]) : opts.default?.());

  watch(cookie, () => {
    document.cookie = serializeCookie(name, cookie.value, opts);
  });

  return cookie;
};

function serializeCookie(name, value, opts = { secure: true }) {
  if (value === null || value === undefined) {
    return serialize(name, value, { ...opts, maxAge: -1 });
  }
  return serialize(name, value, opts);
}

function encrypt(value) {
  return CryptoJS.AES.encrypt(value, useRuntimeStore().$state.cookie_secret).toString();
}

function decrypt(value) {
  const bytes = CryptoJS.AES.decrypt(value, useRuntimeStore().$state.cookie_secret);
  return bytes.toString(CryptoJS.enc.Utf8);
}
