import { ref, computed, watchEffect } from 'vue'
import { defineStore } from 'pinia'

import { useRuntimeStore } from '@/stores/runtimeStore'
// //utils
import axiosAuth from '@/utils/AxiosAuth'
import { useCompany } from '@/stores/companyStore'

const projectStatuses = ['estimate', 'billing', 'dispatch']

export const useSearch = defineStore('search', () => {
  const activeFilter = ref('estimate')
  const searchResults = ref([])
  const globalSearchResults = ref([])
  const noResultFound = ref(false)
  const globalEstimates = ref([{ estimate: [] }])
  const globalProfiles = ref([{ profile: [] }])
  const globalClients = ref([{ client: [] }])
  const globalCompanies = ref([{ company: [] }])
  const { getCompaniesList } = useCompany()
  const { globalSearchCompany } = storeToRefs(useCompany())

  const widgetsData = ref({
    total_count: 0,
    total_clients: 0,
    total_value: 0
  })
  const clientWidgetsData = ref({
    total_count: 0,
    total_clients: 0,
    total_value: 0
  })
  const searchData = ref({
    type: null
  })
  const resultLimit = ref(100)
  const endOfList = ref(false)
  const desc = ref(false)
  const offset = ref(0)
  const currentPage = ref(1)
  const next_url = ref(null)
  const searchText = ref(null)
  const orderBy = ref('last_updated.time')
  const filterName = ref(null)
  const filterRole = ref(null)
  const filterClient = ref(null)
  const filterJobNumber = ref(null)
  const filterArborist = ref(null)
  const filterProfile = ref(null)
  const filterStatus = ref(null)
  const filterType = ref(null)
  const filterEmail = ref(null)
  const filterPhone = ref(null)
  const filterServiceAddress = ref(false)
  const filterAgreement = ref(false)
  const filterActive = ref(null)
  const status = ref(null)
  const secondaryStatus = ref(null)
  const showAdvancedSearch = ref(false)
  const isLoading = ref(false)
  const isSearchLoading = ref(false)
  const startDate = ref(false)
  const endDate = ref(false)
  const agreementId = ref(null)
  const execpt = ref(null)
  const lost = ref(false)
  const waitingList = ref([])
  const scheduledList = ref([])
  const waitingSearchText = ref(null)
  const scheduledSearchText = ref(null)
  const crewReportData = ref([])
  const selectedCrew = ref(null)
  const crewJobsData = ref([])
  const base64CompanyLogo = ref('')

  const agreementProjectResults = ref([])

  const toggleAdvancedSearch = () => {
    showAdvancedSearch.value = !showAdvancedSearch.value
  }
  const hideAdvancedSearch = () => {
    showAdvancedSearch.value = false
  }

  const resetFilters = () => {
    filterName.value = null
    filterEmail.value = null
    filterPhone.value = null
    filterStatus.value = null
    // secondaryStatus.value = null
    filterServiceAddress.value = false
    filterAgreement.value = false
    status.value = null
    filterActive.value = true
    filterRole.value = null
    filterClient.value = null
    filterJobNumber.value = null
    filterArborist.value = null
    filterType.value = null
    filterProfile.value = null
    searchText.value = null
    desc.value = false
    startDate.value = false
    endDate.value = false

    searchByFilter()
  }

  const resetGlobalFilters = () => {
    filterName.value = null
    filterEmail.value = null
    filterPhone.value = null
    filterStatus.value = null
    // secondaryStatus.value = null
    filterServiceAddress.value = false
    filterAgreement.value = false
    status.value = null
    filterActive.value = true
    filterRole.value = null
    filterClient.value = null
    filterJobNumber.value = null
    filterArborist.value = null
    filterType.value = null
    filterProfile.value = null
    searchText.value = null
    desc.value = false
    startDate.value = false
    endDate.value = false
  }

  const setActiveFilter = (filter) => {
    if (filter === 'services') {
      activeFilter.value = 'projects/services'
    }
    if (filter !== 'dispatch') {
        execpt.value = null;
        waitingList.value = [];
        scheduledList.value = [];

        if (filter !== 'estimate' && filter !== 'billing') {
            secondaryStatus.value = null;
        }
    }


    // offset.value = 0
    activeFilter.value = filter
    resetFilters()
    endOfList.value = false
  }
  const params = computed(() => {
    return {
      limit: resultLimit.value,
      // offset: offset.value,
      execpt: execpt.value,
      lost: lost.value,
      page: currentPage.value,
      // offset: currentPage.value || 1,
      ...(projectStatuses.includes(activeFilter.value)
        ? { status: activeFilter.value }
        : {}),
      ...(offset.value ? { offset: offset.value } : {}),
      ...(filterName.value ? { search_text: filterName.value } : {}),
      ...(filterEmail.value ? { email: filterEmail.value } : {}),
      ...(filterPhone.value ? { phone: filterPhone.value } : {}),
      ...(filterStatus.value ? { filter_status: filterStatus.value } : {}),
      ...(secondaryStatus.value
        ? { secondary_status: secondaryStatus.value }
        : {}),
      ...(filterServiceAddress.value
        ? { service_address_id: filterServiceAddress.value }
        : {}),
      ...(filterAgreement.value ? { agreement_id: filterAgreement.value } : {}),
      ...(status.value ? { status: status.value } : {}),
      ...(filterActive.value !== null ? { active: filterActive.value } : {}),
      ...(filterRole.value ? { filter_role: filterRole.value } : {}),
      ...(filterClient.value ? { client_id: filterClient.value } : {}),
      ...(filterJobNumber.value ? { search_text: filterJobNumber.value } : {}),
      ...(filterArborist.value ? { arborist_id: filterArborist.value } : {}),
      ...(filterType.value ? { type: filterType.value } : {}),
      ...(searchText.value ? { search_text: searchText.value } : {}),
      ...(orderBy.value ? { order_by: orderBy.value } : {}),
      ...(desc.value ? { desc: desc.value } : {}),
      ...(agreementId.value ? { agreement_id: agreementId.value } : {}),
      ...(startDate.value ? { start_date: new Date(startDate.value) } : {}),
      ...(endDate.value ? { end_date: new Date(endDate.value) } : {})
    }
  })

  const getPath = () => {
    // estimate, dispatch, and billing all call against base projects endpoint with query param for status
    if (projectStatuses.includes(activeFilter.value)) {
      return 'projects'
    } else if (activeFilter.value === 'services') {
      return 'projects/services'
    } else if (activeFilter.value === 'agreement') {
      status.value = 'agreement'
      secondaryStatus.value = 'signed'
      return 'projects'
    } else if (activeFilter.value === 'products') {
      return 'projects/products'
    } else if (activeFilter.value === 'crew') {
      return 'profile/crew'
    } else if (activeFilter.value === 'packages') {
      return 'recurring_services/packages'
    } else {
      return `${activeFilter.value}`
    }
  }

  const searchByFilter = async () => {
    searchResults.value = []
    const root = useRuntimeStore().$state.apiRoot
    isLoading.value = true
    params.value.limit = params.value.limit === -1 ? 1000 : params.value.limit
    await axiosAuth
      .get(`${root}/${getPath()}/`, { params: params.value })
      .then((res) => {
        if (secondaryStatus.value == null && execpt.value === 'waiting') {
          scheduledList.value = res.data.projects || res.data
        } else if (
          secondaryStatus.value === 'waiting' &&
          execpt.value == null
        ) {
          waitingList.value = res.data.projects || res.data
        } else {
          searchResults.value = res.data.projects || res.data
        }
        widgetsData.value.total_count = res.data.total_projects
        widgetsData.value.total_value = res.data.total_value

        isLoading.value = false
        if (searchResults.value.length < resultLimit.value) {
          endOfList.value = true
          return
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const globalSearchClients = async () => {
    const root = useRuntimeStore().$state.apiRoot

    await axiosAuth
      .get(`${root}/clients/`, { params: params.value })
      .then((res) => {
        globalClients.value.client = res.data

        // isLoading.value = false
        if (globalClients.value.length < resultLimit.value) {
          endOfList.value = true
          return
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const globalSearchProfiles = async () => {
    const root = useRuntimeStore().$state.apiRoot

    await axiosAuth
      .get(`${root}/profile/`, { params: params.value })
      .then((res) => {
        globalProfiles.value.profile = res.data

        // isLoading.value = false
        if (globalProfiles.value.length < resultLimit.value) {
          endOfList.value = true
          return
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const globalSearch = async (searchQuery) => {
    globalSearchResults.value = []
    globalEstimates.value = []
    const root = useRuntimeStore().$state.apiRoot
    isSearchLoading.value = true
    params.value.limit = 20
    params.value.status = null
    params.value.secondary_status = null
    params.value.search_text = searchQuery

    await axiosAuth
      .get(`${root}/projects/`, { params: params.value })
      .then((res) => {
        globalEstimates.value.estimate = res.data

        // isLoading.value = false
        if (globalEstimates.value.length < resultLimit.value) {
          endOfList.value = true
          return
        }
      })
      .catch((err) => {
        console.log(err)
      })

    if (useCookie('userData').value.roles.includes('superadmin')) {
      await getCompaniesList({
        search_text: searchQuery,
        origin: 'global_search'
      }).then(() => (globalCompanies.value.company = globalSearchCompany.value))
    } else {
      await globalSearchClients()
      await globalSearchProfiles()
    }

    if (
      globalEstimates?.value.estimate?.length != 0 ||
      globalClients?.value.client?.length != 0 ||
      globalProfiles?.value.profile?.length != 0 ||
      globalCompanies?.value.company?.length != 0
    ) {
      globalSearchResults.value = [
        globalEstimates.value,
        globalClients.value,
        globalProfiles.value,
        globalCompanies.value
      ]
      noResultFound.value = false
    }

    if (
      !globalEstimates?.value.estimate?.length &&
      !globalClients?.value.client?.length &&
      !globalProfiles?.value.profile?.length &&
      !globalCompanies?.value.company?.length
    ) {
      noResultFound.value = true
    }

    isSearchLoading.value = false
  }

  const getWidgetData = async () => {
    widgetsData.value = []
    const root = useRuntimeStore().$state.apiRoot
    const userData = useCookie('userData').value
    isLoading.value = true
    await axiosAuth
      .get(`${root}/projects/widget_data/`, {
        params: { status: params.value.status, company_id: userData.company_id }
      })
      .then((res) => {
        widgetsData.value = res.data
        isLoading.value = false
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getCrewReportData = async () => {
    if (
      startDate.value != false &&
      endDate.value != false &&
      selectedCrew.value != null
    ) {
      const root = useRuntimeStore().$state.apiRoot
      const userData = useCookie('userData').value
      isLoading.value = true
      await axiosAuth
        .get(`${root}/report/crew_data`, {
          params: {
            crew_id: selectedCrew.value,
            start_date: startDate.value,
            end_date: endDate.value
          }
        })
        .then((res) => {
          crewReportData.value = res.data.crew_data
          crewJobsData.value = res.data.crew_jobs
          isLoading.value = false
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const getPdfLogoBase64 = async (imgUrl) => {
    if (imgUrl != null) {
      const root = useRuntimeStore().$state.apiRoot
      await axiosAuth
        .get(`${root}/report/image_conversion`, {
          params: { imgUrl: imgUrl }
        })
        .then((res) => {
          base64CompanyLogo.value = res.data.image_base64
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const getClientWidgetData = async () => {
    clientWidgetsData.value = []
    const root = useRuntimeStore().$state.apiRoot
    isLoading.value = true
    await axiosAuth
      .get(`${root}/clients/client_widget_data`)
      .then((res) => {
        clientWidgetsData.value = res.data
        isLoading.value = false
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const agreementProjectSearch = async (projectIds) => {
    const root = useRuntimeStore().$state.apiRoot
    let url = `${root}/projects/?${projectIds}`
    await axiosAuth
      .get(`${url}`, {
        params: {
          limit: 100
        }
      })
      .then((res) => {
        console.log('agreementProjectSearch', res.data)
        agreementProjectResults.value = res.data
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getMoreResults = async () => {
    offset.value = offset.value += resultLimit.value

    const root = useRuntimeStore().$state.apiRoot
    await axiosAuth
      .get(`${root}/${getPath()}/`, { params: params.value })
      .then((res) => {
        if (searchResults.value.length < offset.value) {
          endOfList.value = true
          return
        }
        searchResults.value = [...searchResults.value, ...res.data]
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const values = {
    activeFilter,
    searchResults,
    globalSearchResults,
    noResultFound,
    searchData,
    resultLimit,
    endOfList,
    desc,
    offset,
    currentPage,
    next_url,
    searchText,
    globalCompanies,
    orderBy,
    filterName,
    filterRole,
    filterJobNumber,
    filterArborist,
    filterProfile,
    filterStatus,
    filterClient,
    filterServiceAddress,
    filterAgreement,
    filterType,
    filterEmail,
    filterPhone,
    filterActive,
    showAdvancedSearch,
    params,
    isLoading,
    isSearchLoading,
    status,
    secondaryStatus,
    startDate,
    endDate,
    agreementId,
    agreementProjectResults,
    widgetsData,
    clientWidgetsData,
    execpt,
    lost,
    waitingList,
    scheduledList,
    waitingSearchText,
    scheduledSearchText,
    crewReportData,
    selectedCrew,
    crewJobsData,
    base64CompanyLogo
  }

  const actions = {
    toggleAdvancedSearch,
    hideAdvancedSearch,
    setActiveFilter,
    searchByFilter,
    globalSearch,
    getMoreResults,
    resetFilters,
    resetGlobalFilters,
    agreementProjectSearch,
    getWidgetData,
    getClientWidgetData,
    getCrewReportData,
    getPdfLogoBase64
  }

  return {
    ...values,
    ...actions
  }
})
