import { ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
// import router from '@/router'
import axiosAuth from '@/utils/AxiosAuth'
import { cloneDeep } from 'lodash'
import { notify } from 'notiwind'
// import { useToggle } from '@/composables'
// Pinia
import { useRuntimeStore } from '@/stores/runtimeStore'
import { useClientStore } from '@/stores/clientStore'
import { useAttach } from '@/stores/attachmentStore'

export const useServiceAddress = defineStore('serviceAddressStore', () => {
  const isNew = ref(true)
  const index = ref(0)
  const addressId = ref('')

  const selectedServiceAddress = ref({})
  const editedServiceAddress = ref({})
  const clientServiceAddresses = ref([])
  const isLoading = ref(false)
  const isEditing = ref(false)

  const lightboxImage = ref(null)
  const showLightbox = ref(useToggle(false))

  const root = useRuntimeStore().$state.apiRoot
  const { selectedClient, clientMessages } = storeToRefs(useClientStore())
  const { messageAttachments } = storeToRefs(useAttach())

  const toggleEditing = () => {
    isEditing.value = !isEditing.value
  }

  const setSelectedServiceAddress = (serviceAddress) => {
    selectedServiceAddress.value = serviceAddress
  }

  const setNewServiceAddress = () => {
    editedServiceAddress.value = {
      property_name: '',
      address: {
        line_1: '',
        line_2: '',
        city: '',
        state: '',
        zip: ''
      },
      note: '',
      active: ''
    }
  }

  const createServiceAddress = async () => {
    let result
    // current POST assumes a flat object for address properties
    const preparedAddress = {
      property_name: editedServiceAddress.value.property_name,
      note: editedServiceAddress.value.note,
      ...editedServiceAddress.value.address
    }
    await axiosAuth
      .post(
        `${root}/clients/${selectedClient.value.id}/service-address/`,
        preparedAddress
      )
      .then((res) => {
        result = res.data
        // TODO: review with Matt proposed pattern for post/response for service addresses
        selectedClient.value.service_addresses.push(result)
        notify(
          {
            group: 'success',
            title: 'Success',
            text: 'Address has been added'
          },
          2000
        )
        isNew.value = true
      })
      .catch((err) => {
        console.log(err)
        notify(
          {
            group: 'error',
            title: 'Error Adding Service Address',
            text: 'We had a problem adding this service address.'
          },
          2000
        )
      })
  }

  const saveServiceAddress = async () => {
    let result
    // We always have the right selectedClient.value.id when creating a new service address
    // .with the getClientById call in Client View

    await axiosAuth
      .put(
        `${root}/clients/${selectedClient.value.id}/service-address/${addressId.value}`,
        editedServiceAddress.value
      )
      .then((res) => {
        // api doesn't return contacts on update -- only on GET -- so we need to merge
        // the existing so they don't disappear in the UI on save
        result = {
          ...res.data,
          contacts: selectedServiceAddress.value.contacts
            ? selectedServiceAddress.value.contacts
            : []
        }
        // TODO: review with Matt proposed pattern for post/response for service addresses
        selectedServiceAddress.value = result
        selectedClient.value.service_addresses[index.value] = result
        notify(
          {
            group: 'success',
            title: 'Service Address Updated',
            text: 'Your changes to this service address have been saved.'
          },
          2000
        )
        isNew.value = true
      })
      .catch((err) => {
        console.log(err)
        notify(
          {
            group: 'error',
            title: 'Error Saving Service Address',
            text: 'We had a problem saving this service address.'
          },
          2000
        )
      })
  }

  const editServiceAddress = () => {
    isNew.value = false
    isEditing.value = true
    editedServiceAddress.value = cloneDeep(selectedServiceAddress.value)
  }

  const getServiceAddressById = async (clientId, serviceAddressId) => {
    // write similar to above but using the clientId in the get then the serviceAddress as the second parameter
    let result
    await axiosAuth
      .get(`${root}/clients/${clientId}/service-address/${serviceAddressId}`)
      .then((res) => {
        result = res.data
        selectedServiceAddress.value = result
      })
      .catch((err) => {
        console.log(err)
        notify(
          {
            group: 'error',
            title: 'Error Retrieving Service Address',
            text: 'We a problem retrieving this service address info.'
          },
          2000
        )
      })
  }

  const getServiceAddressList = async (clientId) => {
    // write similar to above but using the clientId in the get then the serviceAddress as the second parameter
    let result
    await axiosAuth
      .get(`${root}/clients/${clientId}/service-address/`)
      .then((res) => {
        result = res.data
        clientServiceAddresses.value = result
      })
      .catch((err) => {
        console.log(err)
        notify(
          {
            group: 'error',
            title: 'Error Retrieving Service Address',
            text: 'We a problem retrieving this service address info.'
          },
          2000
        )
      })
  }

  const postNewMessage = async (clientId, message) => {
    message.attachments = messageAttachments.value
    await axiosAuth
      .post(`${root}/clients/${clientId}/message/`, message)
      .then((res) => {
        let result = {
          ...res.data
        }
        clientMessages.value.unshift(result)
        messageAttachments.value = []
        notify(
          {
            group: 'success',
            title: 'New Message Added',
            text: 'Message added successfully.'
          },
          2000
        )
      })
      .catch((err) => {
        console.log(err)
        notify(
          {
            group: 'error',
            title: 'Error Adding Message',
            text: 'We had a problem adding this message.'
          },
          2000
        )
      })
    messageAttachments.value = []
  }

  const updateContact = (updatedContact, currentServiceAddressId) => {
    // TODO - some bugs still
    console.log('updatedContact', updatedContact)
    // console.log('serviceAddressId', currentServiceAddressId)
    // console.log('foundinSaArray', updatedContact.service_addresses.includes(address => address.id === currentServiceAddressId))

    const indexContactOriginallyInSa =
      selectedServiceAddress.value.contacts.findIndex(
        (contact) => contact.id === updatedContact.id
      )
    const contactIncludesServiceAddress = updatedContact.service_addresses.find(
      (address) => address.id === currentServiceAddressId
    )
    const newContactsArray = [...selectedServiceAddress.value.contacts]
    // if updating a contact from a Service Address and the contact is no longer assigned to this address
    // remove the contact from the service address array
    if (indexContactOriginallyInSa && contactIncludesServiceAddress) {
      // if the contact is assigned to this address, update the contact in the array
      // and return the nested state, otherwise the previous value will persist
      newContactsArray.splice(indexContactOriginallyInSa, 1, updatedContact)
    } else if (indexContactOriginallyInSa && !contactIncludesServiceAddress) {
      // if they've been unassigned from this address, remove them from the array
      newContactsArray.splice(indexContactOriginallyInSa, 1)
    }
    // merge final result
    selectedServiceAddress.value = {
      ...selectedServiceAddress.value,
      contacts: newContactsArray
    }
  }

  const deleteServiceAddressMessage = async (
    clientId,
    serviceAddressId,
    messageId
  ) => {
    if (selectedServiceAddress.value.id === serviceAddressId) {
      await axiosAuth
        .delete(`${root}/clients/${clientId}/message/${messageId}`)
        .then((res) => {
          console.log(res.status)
          notify(
            {
              group: 'success',
              title: 'Message Deleted',
              text: 'Message deleted successfully.'
            },
            2000
          )
          clientMessages.value = clientMessages.value.filter(
            (message) => message.id !== messageId
          )
        })
        .catch((err) => {
          console.log(err)
          notify(
            {
              group: 'error',
              title: 'Error Deleting Message',
              text: 'We had a problem deleting this message.'
            },
            2000
          )
        })
    }
  }
  const values = {
    isNew,
    index,
    addressId,
    selectedServiceAddress,
    editedServiceAddress,
    clientServiceAddresses,
    isLoading,
    isEditing,
    clientMessages,
    lightboxImage,
    showLightbox
  }

  const actions = {
    toggleEditing,
    setSelectedServiceAddress,
    getServiceAddressList,
    setNewServiceAddress,
    editServiceAddress,
    createServiceAddress,
    saveServiceAddress,
    getServiceAddressById,
    updateContact,
    postNewMessage,
    deleteServiceAddressMessage
  }

  return {
    ...values,
    ...actions
  }
})
