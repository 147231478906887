// import router from '@/router'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import axiosAuth from '@/utils/AxiosAuth'
import { useRuntimeStore } from '@/stores/runtimeStore'
import { cloneDeep } from 'lodash'
import { useUserStore } from '@/stores/userStore'
import { notify } from 'notiwind'

import { fetchProfileById, updateProfile, updateSuperAdminProfile } from '@/services/profileServices'
import { useCookie } from '@/@core/composable/useCookie'
import { useSuperAdmin } from './superAdminStore'
// import { postAttachment } from '@/services/attachmentServices'

// import { postAttachment } from '@/services/attachmentServices';

export const useProfileStore = defineStore('profileStore', () => {
  const isNew = ref(false)
  const isLoading = ref(false)
  const isEditing = ref(false)
  const uploading = ref(false)
  const selectedProfile = ref(null)
  const editedProfile = ref(null)
  const profiles = ref([])
  const awaitingProfileResults = ref(true)
  const noProfileResults = ref(false)
  const profileImageFile = ref(null)
  const totalProfiles = ref(0)
  const totalProfilesCards = ref(null)
  const currentProfile = ref(null)
  const root = useRuntimeStore().$state.apiRoot

  const toggleEditing = () => {
    isEditing.value = !isEditing.value
  }

  const setNewProfile = () => {
    isNew.value = true
    isEditing.value = true
    editedProfile.value = {
      name: {
        first: '',
        last: ''
      },
      roles: [],
      phone: '',
      extension: '',
      email: '',
      profile_image: null
    }
  }

  const getProfiles = async (params) => {
    isLoading.value = true
    await axiosAuth
      // accepted params: offset<int>,
      .get(
        `${root}/profile/`,
        params ? params : { params: { offset: 0, limit: 100 } }
      )
      .then((res) => {
        profiles.value = res.data
        isLoading.value = false
      })

    // Profile count API should be present in the backend for the below code.
    // In case of any issue with profile list  you can set the totalProfiles.value mannually
    // e.g: totalProfiles.value =  25
    await axiosAuth
      .get(
        `${root}/profile/count`, { params: {enabled:  params.params.filter_enabled} }
      )
      .then((res) => {
        totalProfiles.value =  res.data.count
      })
  }

  const getProfilesCards = async () => {
    await axiosAuth
    .get(
      `${root}/profile/count`
    )
    .then((res) => {
      totalProfiles.value =  res.data.count
    })

    await axiosAuth.get(`${root}/profile/`, { params: { offset: 0, limit: totalProfiles.value } })
      .then((res) => {
        totalProfilesCards.value = res.data;
        isLoading.value = false;
      })
      .catch((error) => {
        console.error('Error fetching profiles :', error);
        isLoading.value = false;
      });
  };
  const getSelectedProfile = async (id) => {
    isNew.value = false
    isLoading.value = true
    const profileResult = await fetchProfileById(id)
    isLoading.value = false
    selectedProfile.value = profileResult
  }
  const getProfileById = async (id) => {
    currentProfile.value = selectedProfile.value
    selectedProfile.value = null
    isNew.value = false
    isLoading.value = true
    const profileResult = await fetchProfileById(id)
    isLoading.value = false
    selectedProfile.value = profileResult
  }

  const editProfile = () => {
    editedProfile.value = cloneDeep(selectedProfile.value)
    isEditing.value = true
  }

  const saveProfile = async (profile) => {
    const { currentUser} = storeToRefs(useUserStore())
    const {setCurrentUser} = useUserStore()
    if (profile.profile_image?.id) {
      profile.profile_image = profile.profile_image.id
    }
    
    if (!objectsEqual(profile,(useCookie('userData').value))){
    const updatedProfile = currentUser.value.roles.includes('superadmin')? await updateSuperAdminProfile(profile):await updateProfile(profile)
    selectedProfile.value = updatedProfile
      if (currentUser.value?.id === selectedProfile.value?.id) {
        useCookie('userData').value = updatedProfile
        currentUser.value = updatedProfile
      }
    editedProfile.value  = updatedProfile
    isEditing.value = false
    }
    // if (currentUser.value?.id === selectedProfile.value?.id) {
    //   setCurrentUser(selectedProfile.value.id)
    // }
  }
  
  const objectsEqual = (obj1, obj2)  => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}
  const createProfile = async (newProfile) => {
    if (newProfile.profile_image?.id) {
      newProfile.profile_image = {
        id: newProfile.profile_image.id
      }
    }
    await axiosAuth
      .post(`${root}/profile/`, newProfile)
      .then((res) => {
        editedProfile.value = res.data
        // router.replace(`/profile/${editedProfile.value.id}`)
        notify(
          {
            group: 'success',
            title: 'Profile Created',
            text: 'Profile created successfully.'
          },
          2000
        )
      })
      .catch((err) => {
        console.log(err)
        notify(
          {
            group: 'error',
            title: 'Error Creating Profile',
            text: err?.response?.data? err.response.data.message:'We had a problem creating this profile.'
          },
          2000
        )
      })
  }

  const deleteProfile = async (id) => {
    await axiosAuth
      .delete(`${root}/profile/${id}`)
      .then(() => {
        notify(
          {
            group: 'success',
            title: 'Profile Deleted',
            text: 'Profile deleted successfully.'
          },
          2000
        )
      })
      .catch(() => {
        notify(
          {
            group: 'error',
            title: 'Error Deleting Profile',
            text: 'We were unable to delete this profile. Please try again or contact support.'
          },
          2000
        )
      })
  }

  const values = {
    isNew,
    isLoading,
    isEditing,
    selectedProfile,
    editedProfile,
    profiles,
    awaitingProfileResults,
    noProfileResults,
    profileImageFile,
    totalProfiles,
    currentProfile,
    totalProfilesCards,
    uploading
  }

  const actions = {
    setNewProfile,
    editProfile,
    toggleEditing,
    getProfiles,
    getProfilesCards,
    getProfileById,
    saveProfile,
    createProfile,
    deleteProfile,
    getSelectedProfile
  }

  return {
    ...values,
    ...actions
  }
})
