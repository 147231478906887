import { defineStore } from 'pinia'
import axiosAuth from '@/utils/AxiosAuth'
import { useRuntimeStore } from '@/stores/runtimeStore'
import { useAttach } from '@/stores/attachmentStore'
import {router } from '@/plugins/1.router/index'
import { notify } from 'notiwind'
import { useCookie } from '@/@core/composable/useCookie'
import { getUserAbilities } from '@/utils/userAbilityRules'

export const useUserStore = defineStore({
  id: 'userStore',
  state: () => ({
    currentUser: undefined,
    isAdmin: false,
    isCrew: false
  }),
  getters: {
    getCurrentUserProfile() {
      return this.currentUser
    }
  },
  actions: {
    async setCurrentUser(id) {

      const runtimeStore = useRuntimeStore()
      await axiosAuth
        .get(`${runtimeStore.apiRoot}/profile/${id}/`)
        .then((res) => {
          this.currentUser = res.data
          useCookie('userData').value = this.currentUser
         
        })
        .catch((err) => {
          console.log(err)
        })
        useCookie('userAbilityRules').value = await getUserAbilities(this.currentUser)
    },

    async createProfile(newProfile) {
      const { imageUrl, resetImageUrl } = useAttach()
      if (imageUrl) {
        newProfile.profile_image = imageUrl
      }

      const runtimeStore = useRuntimeStore()
      axiosAuth
        .post(`${runtimeStore.apiRoot}/profile/`, newProfile)
        .then((res) => {
          resetImageUrl()
          router.replace(`/${res.data.id}`)
        })
        .catch((err) => {
          console.log(err)
          notify(
            {
              group: 'error',
              title: 'Error',
              text: err.response.data
            },
            2000
          ) // 4s
        })
    },
    async check_intercom_user(id) {
      const runtimeStore = useRuntimeStore()
      await axiosAuth
        .get(`${runtimeStore.apiRoot}/profile/intercom_user/${id}/`)
        .then((res) => {
        let contact_data = res.data.contactData
      if (contact_data.total_count > 0){
        useCookie('intercomUser').value = contact_data.data[0].external_id
        useCookie('intercomHash').value = res.data.userHash
       }
         })
        .catch((err) => {
          console.log(err)
        })
    }
  }
})