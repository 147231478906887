import { setupWorker } from 'msw'

// Handlers
import { handlerAppBarSearch } from '@db/app-bar-search/index'
// import { handlerAppsAcademy } from '@db/apps/academy/index'
// import { handlerAppsCalendar } from '@db/apps/calendar/index'
// import { handlerAppsChat } from '@db/apps/chat/index'
// import { handlerAppsEcommerce } from '@db/apps/ecommerce/index'
// import { handlerAppsEmail } from '@db/apps/email/index'
// import { handlerAppsInvoice } from '@db/apps/invoice/index'
// import { handlerAppLogistics } from '@db/apps/logistics/index'
// import { handlerAppsPermission } from '@db/apps/permission/index'
import { handlerAppsUsers } from '@db/apps/users/index'
// import { handlerAuth } from '@db/auth/index'
// import { handlerPagesDatatable } from '@db/pages/datatable/index'
// import { handlerPagesFaq } from '@db/pages/faq/index'
// import { handlerPagesHelpCenter } from '@db/pages/help-center/index'
import { handlerPagesProfile } from '@db/pages/profile/index'

const worker = setupWorker(...handlerAppBarSearch,...handlerPagesProfile ,...handlerAppsUsers)
export default function () {
  const workerUrl = `${import.meta.env.BASE_URL ?? '/'}mockServiceWorker.js`

  worker.start({
    serviceWorker: {
      url: workerUrl,
    },
    onUnhandledRequest: 'bypass',
  })
}
